import { all } from "redux-saga/effects";

import authSaga from "./auth/saga";
import layoutSaga from "./layout/saga";
import fetchProductSaga from "./product/saga";
import fetchSubAdminSaga from "./subAdmin/saga";
import categorySaga from "./category/saga";
import taskSaga from "./tasks/saga";
import permissionSaga from "./permissions/saga";
import customerSaga from "./customers/saga";
import orderSaga from "./orders/saga";
import adminProfileSaga from "./adminProfile/saga";
import userProfileSaga from "./userProfile/saga";
import UserProductSaga from "./UserRedux/UserProduct/saga";
import UserOrderSaga from "./UserRedux/UserOrder/saga";
import UserCartSaga from "./UserRedux/UserCart/saga";
import DashboardSaga from "./dashboard/saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    layoutSaga(),
    fetchProductSaga(),
    fetchSubAdminSaga(),
    categorySaga(),
    taskSaga(),
    permissionSaga(),
    customerSaga(),
    orderSaga(),
    adminProfileSaga(),
    userProfileSaga(),
    UserProductSaga(),
    UserOrderSaga(),
    UserCartSaga(),
    DashboardSaga(),
  ]);
}
