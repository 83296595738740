import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

import { dashboardApiResponseSuccess, dashboardApiResponseError } from "./actions";
import { DashboardActionTypes } from "./constants";
// import {fetchProductsAPI} from "./api";

import notify from "../../components/Notification/notify";
import Swal from "sweetalert2";
import { fetchUserStats } from "../../helpers/api/dashboardApi";

interface TaskData {
  payload: {
    name: string;
    price: string;
    stock: string;
  };
  type: string;
}

function* fetchUserStatsSaga({ payload }: any): SagaIterator {
  try {

    const response = yield call(fetchUserStats);
    const productData: any = response.data;
    yield put(dashboardApiResponseSuccess(DashboardActionTypes.FETCH_USERS_STATS, productData));
  } catch (error: any) {
    yield put(dashboardApiResponseError(DashboardActionTypes.FETCH_USERS_STATS, error));
  }
}

export function* watchFetchTask() {
  yield takeEvery(DashboardActionTypes.FETCH_USERS_STATS, fetchUserStatsSaga);
}

function* DashboardSaga() {
  yield all([
    fork(watchFetchTask),
  ]);
}

export default DashboardSaga;
