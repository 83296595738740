import { DashboardActionTypes } from "./constants";

const INIT_STATE = {
  taskDetails: [],
  loading: false,
  error: null,
  is_add: false,
  is_edit: false,
  addSuccessMessage: null,
  is_deleted: false,
  editSuccessMessage: null,
  subAdminDetails: null,
  editStatusMessage: null,
  isStatus: false,
  CustomerDetails: null,
  clientTaskDetails: null,
};

interface taskData {
  loading: boolean;
  error: string | null;
  userStatsData: any;
}

export interface DashboardActionType {
  type:
  | DashboardActionTypes.API_RESPONSE_SUCCESS
  | DashboardActionTypes.API_RESPONSE_ERROR
  | DashboardActionTypes.FETCH_USERS_STATS
  payload: {
    actionType?: string;
    data?: taskData | {};
    error?: string;
  };
}

interface State {
  loading?: boolean;
  userStatsData?: any;
}

const DashboardReducer = (
  state: State = INIT_STATE,
  action: DashboardActionType
): any => {
  switch (action.type) {
    case DashboardActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case DashboardActionTypes.FETCH_USERS_STATS: {
          return {
            ...state,
            userStatsData: action.payload.data,
            loading: false,
          };
        }

        default:
          return { ...state };
      }

    case DashboardActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case DashboardActionTypes.FETCH_USERS_STATS: {
          return {
            ...state,
            loading: false,
          };
        }

        default:
          return { ...state };
      }

    case DashboardActionTypes.FETCH_USERS_STATS:
      return { ...state, loading: true };

    default:
      return { ...state };
  }
};
export default DashboardReducer;
