import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal, Button } from "react-bootstrap";
import { FormInput } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  AddSubAdminAction,
  addTaskAction,
  editStatusAction,
  editTaskAction,
  fetchSubAdminListAction
} from "../../../redux/actions";
import { Link } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";

interface FormValues {
  status: any;
}

interface ShowSubadminTaskProps {
  show: boolean;
  task: any;
  onHide: () => void;
  onSubmit: (value: any) => void;
  customerList: any;
}

const statusVal = [
  {
    status: 0,
    name: "Active"
  },
  {
    status: 1,
    name: "Finished"
  }
];

const ShowClientTask = ({
  show,
  task,
  onHide,
  onSubmit,
  customerList
}: ShowSubadminTaskProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const initialValues: FormValues = {
    status: task?.status || ""
  };
  useEffect(() => {
    dispatch(fetchSubAdminListAction());
  }, [dispatch]);

  const handleSubmit = (
    values: FormValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    // Handle submission here, e.g., send data to backend
    if (Object.keys(task).length > 0) {
      //Update Call
      const formData = {
        task_id: task?.task_id,
        status: parseInt(values?.status)
      };
      dispatch(editStatusAction(formData));
    }
    // resetForm();
  };

  const CustomerProfile = ({ row }: { row: any }) => {
    const imageUrl = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNUKbV4ZCrVOwTMkfnN10Mfhwp7BSiVb64BzDuE_lA9w&s"
    const matchedCustomers = row
    return (
      <div className="table-user">
        {matchedCustomers.map((obj: any) => {
          let customer: any = obj.taskCustomerUserData
          return (
            <Link key={customer.user_id} to="#" className="text-muted">
              <img
                src={
                  customer?.profile_image || imageUrl
                }
                alt=""
                className="avatar-sm img-thumbnail rounded-circle"
              />
            </Link>
          )
        })}
      </div>
    );
  };

  const StatusTask = ({ status }: { status: any }) => {
    return (
      <React.Fragment>
        <span
          className={classNames("badge", "float-end", {
            "bg-soft-success text-success": status === 1,
            "bg-soft-danger text-danger": status === 0
          })}
        >
          {status === 0 ? "Pending" : status === 1 ? "Finished" : "Pending"}
        </span>
      </React.Fragment>
    );
  };

  function formatUnixTimestamp(unixTimestamp: any) {
    // Convert Unix timestamp to milliseconds
    const date = new Date(unixTimestamp * 1000);

    // Define month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    // Get month, day, year, hours, and minutes
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12 || 12;

    // Add leading zero to minutes if necessary
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Construct the formatted string
    const formattedDate = `${month} ${day} ${year} ${hours}:${formattedMinutes} ${period}`;

    return formattedDate;
  }
  const TaskDate = ({ taskDate }: { taskDate: any }) => {
    const order_date = formatUnixTimestamp(taskDate);
    return (
      <>
        {order_date} {/* <small className="text-muted">{}</small> */}
      </>
    );
  };

  return (
    <Modal
      show={show}
      size="lg"
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-light" onHide={onHide} closeButton>
        <Modal.Title className="m-0">Client Task Details</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-4">
        <StatusTask status={task.status} />
        <div className="mb-3">
          <h5 className="">Title:</h5>
          <p className="font-weight-bold text-muted">{task.title}</p>
        </div>

        <div className="mb-3">
          <h5 className="">Task Description:</h5>
          <p className="font-italic text-wrap text-muted">
            {task.task_description}
          </p>
        </div>

        <div className="mb-3">
          <h5 className="">Due Date:</h5>
          <p className="font-italic text-wrap text-muted">
            {task.due_date ? moment(task.due_date * 1000).format('MMMM D, YYYY') : "-"}
          </p>
        </div>

        <div className="clearfix"></div>

        <div className="row">
          <div className="col">
            <p className="font-13 mt-2 mb-0">
              <i className="mdi mdi-calendar"></i> {task.dueDate}
              <TaskDate taskDate={task.created_datetime} />
            </p>
          </div>
          <div className="col-auto">
            <div className="text-end">
              <CustomerProfile row={task.taskCustomerData} />
            </div>
          </div>
        </div>
        {/* <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="mb-3">
              <label htmlFor="user_id">Task ID</label>
              <div>{task?.task_id}</div>
            </div>

            <div className="mb-3">
              <label htmlFor="user_id">Task Title</label>
              <div>{task?.title}</div>
            </div>

            <div className="mb-3">
              <label htmlFor="user_id">Task Description</label>
              <div>{task?.task_description}</div>
            </div>

            <div className="mb-3">
              <label htmlFor="user_id">Status</label>
              <Field
                as="select"
                className="form-select"
                id="user_id"
                name="status"
              >
                <option value="" disabled>
                  Select an status
                </option>

                {statusVal.length > 0 &&
                  statusVal.map((item: any) => (
                    <option key={item.status} value={item.status}>
                      {item.name}
                    </option>
                  ))}
              </Field>
              <ErrorMessage name="status" component="div" />
            </div>
            <div className="text-end mt-3">
              <button className="btn btn-success me-2" type="submit">
                {task && Object.keys(task).length > 0 ? "Update" : "Create"}
              </button>
              <Button onClick={onHide}>Close</Button>
            </div>
          </Form>
        </Formik> */}
        {/* </div> */}
      </Modal.Body>
    </Modal>
  );
};
export default ShowClientTask;
