import { APICore } from "./apiCore";
import config from "../../config";
const api = new APICore();

function fetchUserStats() {
  const baseUrl = config.API_URL + "/admin/userStats";
  return api.create(baseUrl, {});
}

export { fetchUserStats };
