import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// dummy data
import { orders, OrdersItemTypes } from "../Ecommerce/data";
import AddSubAdminTask from "./addSubAdminTask";

// dummy data
import { customers } from "../Ecommerce/data";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  DeleteSubAdminAction,
  deleteTaskAction,
  editStatusAction,
  fetchPermissionListAction,
  fetchSubAdminAction,
  fetchTaskAction,
} from "../../../redux/actions";
import notify from "../../../components/Notification/notify";
import NotificationContainer from "../../../components/Notification/NotificationContainer";
import Swal from "sweetalert2";
import ShowSubAdminTask from "./SubAdminDetailsModal";
import CustomTable from "../../../components/CustomTable";
import { Formik } from "formik";
import { TASK_STATUS } from "../../../constants/status";
import moment from "moment";
import { DateRange } from "rsuite/esm/DateRangePicker";
import AGCustomTable from "../../../components/AGCustomTable";
import DynamicTable from "../../../components/AGCustomTable";

const TaskStatus = [
  {
    status: 1,
    name: "Pending",
    class: "success",
  },
  {
    status: 0,
    name: "Finished",
    class: "danger",
  },
];

/* name column render */
const NameColumn = ({ row }: { row: any }) => {
  return (
    <div className="table-user">
      <img src={row.original.avatar} alt="" className="me-2 rounded-circle" />
      <Link to="#" className="text-body fw-semibold">
        {row.original.name}
      </Link>
    </div>
  );
};

/* last order column render */
const LastOrderColumn = ({ row }: { row: any }) => {
  return (
    <>
      {row.original.last_order.date}{" "}
      <small className="text-muted">{row.original.last_order.time}</small>
    </>
  );
};

// main component
const SubAdminTask = () => {
  const [show, setShow] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const [task, setTask] = useState<any>({});
  const [taskList, setTaskList] = useState<any>([]);
  const [taskStatuses, setTaskStatuses] = useState<any>({});

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);

  const [reqBody, setReqBody] = useState<any>({
    page_record: pageSize && 10,
    page_no: pageIndex && 1,
    filter_by: "0",
    sort_field: "created_datetime",
    sort_order: "desc",
    date_range: {}
  });

  const {
    taskDetails,
    loading,
    error,
    is_add,
    is_deleted,
    is_edit,
    addSuccessMessage,
    editSuccessMessage,
    editStatusMessage,
    isStatus,
  } = useSelector((state: RootState) => ({
    taskDetails: state.TaskReducer.taskDetails,
    loading: state.TaskReducer.loading,
    error: state.TaskReducer.error,
    is_add: state.TaskReducer.is_add,
    is_deleted: state.TaskReducer.is_deleted,
    is_edit: state.TaskReducer.is_edit,
    addSuccessMessage: state.TaskReducer.addSuccessMessage,
    editSuccessMessage: state.TaskReducer.editSuccessMessage,
    editStatusMessage: state.TaskReducer.editStatusMessage,
    isStatus: state.TaskReducer.isStatus,
  }));

  useEffect(() => {
    if (
      taskDetails?.statusCode === 200 &&
      taskDetails?.data?.rows !== undefined
    ) {
      setTaskList(taskDetails?.data?.rows);
      setTotalRecords(taskDetails?.data?.count);
    }
  }, [taskDetails]);

  useEffect(() => {
    if (
      is_add === false &&
      addSuccessMessage !== undefined &&
      addSuccessMessage !== null
    ) {
      onCloseModal();
      dispatch(fetchTaskAction(reqBody));
    }
  }, [is_add, addSuccessMessage, dispatch]);

  useEffect(() => {
    if (
      is_edit === false &&
      editSuccessMessage !== undefined &&
      editSuccessMessage !== null
    ) {
      onCloseModal();
      dispatch(fetchTaskAction(reqBody));
    }
  }, [is_edit, editSuccessMessage, dispatch]);

  // useEffect(() => {
  //   if (is_deleted === false) {
  //     dispatch(fetchTaskAction({}));
  //   }
  // }, [is_deleted, dispatch]);

  useEffect(() => {
    if (
      isStatus === false &&
      editStatusMessage !== undefined &&
      editStatusMessage !== null
    ) {
      onCloseModal();
      dispatch(fetchTaskAction(reqBody));
    }
  }, [isStatus, editStatusMessage, dispatch]);

  // give page size
  const sizePerPageList = [
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "50",
      value: 50,
    }
    // {
    //   text: "All",
    //   value: list.length,
    // },
  ];

  const handleEdit = (id: any) => {
    console.log("Enter Handle Edit", id);
  };

  const handleDelete = (data: any) => {
    const id: any = data?.task_id;
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Dispatch the delete action
          dispatch(deleteTaskAction(id));
          // Show success message after dispatching action
          Swal.fire("Deleted!", "Your item has been deleted.", "success").then(
            () => {
              // Dispatch fetch_data action after success message is shown
              if (is_deleted === false) {
                dispatch(fetchTaskAction(reqBody));
              }
            }
          );
        } else {
          Swal.fire("Cancelled", "Your action has been cancelled :)", "info");
        }
      });
    } catch (error: any) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };
  /* status column render */
  const StatusColumn = ({ row, rowIndex }: { row: any; rowIndex: number }) => {
    const handleChange = (
      e: React.ChangeEvent<HTMLSelectElement>,
      taskId: any
    ) => {
      const selectedStatus = parseInt(e.target.value);
      const data = {
        task_id: taskId,
        status: selectedStatus,
      };
      dispatch(editStatusAction(data));
      setTaskStatuses((prevStatuses: any) => ({
        ...prevStatuses,
        [taskId]: selectedStatus,
      }));
    };
    return (
      <Formik
        initialValues={{ status: row.original.status }}
        onSubmit={() => { }}
      >
        {({ values }) => (
          <select
            className="form-select bg-transparent text-dark"
            id={`status_${rowIndex}`}
            name={`status_${rowIndex}`}
            value={taskStatuses[row.original.task_id] || row.original.status}
            onChange={(e) => handleChange(e, row.original.task_id)}
          // style={{ maxWidth: '150px' }}
          >
            {TASK_STATUS.length > 0 &&
              TASK_STATUS.map((item: any) => (
                <option key={item.status} value={item.status}>
                  {item.name}
                </option>
              ))}
          </select>
        )}
      </Formik>
    );
  };

  const DueDateColumn = ({ row }: { row: any }) => {
    let dueDate = row.original.due_date ? moment.unix(row.original.due_date).format('MMMM D, YYYY') : "-"

    return (
      <>
        <small className="">{dueDate}</small>
        {/* <small className="text-muted">{row.original.due_date}</small> */}
      </>
    );
  };

  // columns to render
  const columns = [
    // {
    //   Header: "ID",
    //   accessor: "task_id",
    //   classes: "table-user"
    // },
    {
      Header: "Title",
      accessor: "title",
      sort: false,
      classes: "table-user text-dark",
      style: {
        maxWidth: "200px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      },
    },
    {
      Header: "Task Description",
      accessor: "task_description",
      style: {
        maxWidth: "500px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      },
    },
    {
      Header: "To Assign",
      accessor: "name",
      Cell: ({ row }: any) => (
        <>
          {row.original.assigned_user?.name ? (<>{row?.original?.assigned_user?.name}</>) : (<>Me</>)}
        </>
      ),
      sort: false,
    },
    {
      Header: "Due Date",
      accessor: "due_date",
      Cell: DueDateColumn,
      sort: false,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: StatusColumn,
      sort: false,
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }: any) => (
        <>
          {!isSubAdmin ? (
            <>
              <Link
                to=""
                className="action-icon text-dark"
                onClick={() => onOpenShowModal(row.original)}
              >
                <i className="mdi mdi-eye"></i>
              </Link>
              <Link
                to=""
                className="action-icon text-dark"
                onClick={() => onOpenEditModal(row.original)}
              >
                <i className="mdi mdi-square-edit-outline"></i>
              </Link>
              <Link
                to=""
                className="action-icon text-dark"
                onClick={() => handleDelete(row.original)}
              >
                <i className="mdi mdi-delete"></i>
              </Link>
            </>
          ) : (
            <>
              <Link
                to=""
                className="action-icon text-dark"
                onClick={() => onOpenShowModal(row.original)}
              >
                <i className="mdi mdi-eye"></i>
              </Link>
            </>
          )}
        </>
      ),
    },
  ];

  const onCloseModal = () => {
    setShow(false);
    setShowDetails(false);
    setTask({});
  };
  const onOpenModal = () => setShow(true);

  const onOpenEditModal = (row: any) => {
    setShow(true);
    setTask(row);
  };

  const onOpenShowModal = (row: any) => {
    setShowDetails(true);
    setTask(row);
  };

  const onHandleSubmit = (formdata: any) => {
    onCloseModal();
  };

  const [isSubAdmin, setIsSubAdmin] = useState<boolean>(false);
  useEffect(() => {
    let userData: any = sessionStorage.getItem("session_user_details");
    userData = JSON.parse(userData);
    if (userData?.user_role === 2) {
      setIsSubAdmin(true);
    }
  }, [isSubAdmin]);


  const sortTasks = async (value: any) => {
    let updatedReqBody = {
      ...reqBody,
      sort_field: value.column ?? "created_datetime",
      sort_order: value.direction ?? "desc",
    };
    if (!value.direction) {
      updatedReqBody = {
        ...reqBody,
        sort_field: "created_datetime",
        sort_order: "desc",
      };
    }
    setReqBody(updatedReqBody);
    await InvokeAPI(updatedReqBody);
  };

  // ? API Call
  const InvokeAPI = async (reqBody: any) => {
    try {
      dispatch(fetchTaskAction(reqBody));
      const totalRecords = taskDetails?.data?.count || 0;
      const rows = taskDetails?.data?.rows || [];
      setTaskList(rows);
      setTotalRecords(totalRecords);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const fetchData = async ({ pageIndex, pageSize, search, filter }: { pageIndex: number; pageSize: number; search?: any, filter?: any }) => {
    const updatedReqBody = {
      ...reqBody,
      page_no: pageIndex + 1,
      page_record: pageSize,
      search: search,
      filter_by: filter,
    };
    setReqBody(updatedReqBody);
    setPageSize(pageSize)
    await InvokeAPI(updatedReqBody);
  };

  const AddTaskButton = () => (
    <div className="text-lg-end mt-xl-0 mt-2">
      {!isSubAdmin ? (
        <Button
          className="btn btn-primary mb-2"
          onClick={onOpenModal}
          style={{ backgroundColor: "#13219D" }}
        >
          <i className="mdi mdi-plus-circle me-1"></i> Add Task
        </Button>
      ) : (
        ""
      )}
    </div>
  )

  // ? FILTER ******************
  const filterDate = async (dates: any) => {
    // Store selected dates in ISO format in an array
    const filterValue: string[] = [];
    if (dates[0] && dates[1]) {
      const startDate = moment(dates[0]).startOf('day'); // Start of the selected date
      const endDate = moment(dates[1]).endOf('day'); // End of the selected date

      filterValue.push(startDate.unix().toString(), endDate.unix().toString());
      // filterValue.push(moment(dates[0]).unix().toString(), moment(dates[1]).unix().toString());
    }

    const updatedReqBody = {
      ...reqBody,
      date_range: {
        from_date: filterValue[0],
        to_date: filterValue[1],
      },
    };

    setReqBody(updatedReqBody);

    await InvokeAPI(updatedReqBody); // Ensure this function is defined
  };

  const [selectedDates, setSelectedDates] = useState<DateRange | null>(null);

  const filterStatus = async (value: any) => {
    const updatedReqBody = {
      ...reqBody,
      filter_by: value,
    };

    setReqBody(updatedReqBody);
    await InvokeAPI(updatedReqBody);
  };

  const FilterStatus = () => {
    return (
      <div className="bd-highlight">
        <select
          className="form-select my-1 my-lg-0 rounded ps-2 px-5"
          id="status-select"
          onChange={(e) => filterStatus(e.target.value)}
          style={{
            backgroundColor: "#3e436c",
            color: "white",
          }}
          value={reqBody.filter_by}
        >
          <option value="0"> All </option>
          <option value="1"> Pending </option>
          <option value="2"> Finished </option>
        </select>
      </div>
    )
  }

  const FilterControls: React.FC = () => {

    const handleDateChange = (dates: DateRange | null) => {
      setSelectedDates(dates);
      filterDate(dates); // Your filtering function
    };

    const handleClean = () => {
      setSelectedDates(null); // Clear dates
      filterDate(null); // Your filtering function for no dates
      filterDate(reqBody);
    };

    return (
      <>
        <div className="d-flex flex-wrap align-items-center">
          <div className="me-2">
            <FilterStatus />
          </div>
          {/* <div className="bd-highlight me-2">
              <DateRangePicker
                value={selectedDates}
                onChange={handleDateChange}
                onClean={handleClean}
                format="dd-MM-yyyy" // Adjust format as needed
                style={{ width: 300 }} // Customize width
              // style={{ width: 300, backgroundColor: "#3e436c", color: "white" }}
              />
            </div> */}
          <div className="">
            <ResetFilter />
          </div>
        </div>
      </>
    );
  };

  const ResetFilter: React.FC = () => {
    return (
      <div className="">
        <Button
          className="btn btn-danger"
          onClick={async () => {
            setSelectedDates(null)
            reqBody.date_range = {}
            reqBody.filter_by = "0"
            await InvokeAPI(reqBody);
          }}
        // style={{ backgroundColor: "#13219D" }}
        >
          {/* <i className="mdi mdi-plus-circle me-1"></i> */}
          Reset
        </Button>
      </div >
    );
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tasks", path: "" },
          { label: "Normal Tasks", path: "/apps/subAdmin", active: true },
        ]}
        title={"Tasks"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body style={{ backgroundColor: "lightblue" }}>
              <Row className="justify-content-between">
                <Col className="col-auto">
                  {/* <form className="d-flex flex-wrap align-items-center">
                    <label htmlFor="inputPassword2" className="visually-hidden">
                      Search
                    </label>
                    <div className="me-3 position-relative">
                      <input
                        type="search"
                        className="form-control my-1 my-lg-0 rounded ps-4 search-input" // Adding padding to accommodate icon
                        id="inputPassword2"
                        placeholder="Search..."
                        onChange={(e) => searchTasks(e.target.value, 0)}
                        style={{ backgroundColor: "#3e436c", color: "white" }}
                      />
                      <div className="position-absolute top-50 start-0 translate-middle-y ms-2">
                        <i className="bi bi-search text-white"></i>
                      </div>
                    </div>
                  </form> */}
                </Col>
                <Col className="col-auto">
                  {/* <div className="text-lg-end mt-xl-0 mt-2">
                    {!isSubAdmin ? (
                      <Button
                        className="btn btn-primary mb-2 me-2"
                        onClick={onOpenModal}
                        style={{ backgroundColor: "#13219D" }}
                      >
                        <i className="mdi mdi-plus-circle me-1"></i> Add Task
                      </Button>
                    ) : (
                      ""
                    )}
                  </div> */}
                </Col>

                <div className="">
                  {
                    // taskList &&
                    <CustomTable
                      loading={loading}
                      columns={columns}
                      data={taskList}
                      pageIndex={pageIndex}
                      pageSize={pageSize}
                      pagination={true}
                      theadClass="table-light"
                      searchBoxClass="mb-2"
                      isSearchable={true}
                      isFilter={false}
                      isSortable={false}
                      sortFunction={sortTasks}
                      fetchData={fetchData}
                      sizePerPageList={sizePerPageList}
                      totalRecords={totalRecords}
                      isCustomActionButton={true}
                      addButton={<AddTaskButton />}
                      isFilterConfigured={true}
                      filterControls={<FilterControls />}
                    />

                    // <div className="ag-theme-alpine"
                    //   style={{
                    //     height: window.innerHeight - 200,
                    //     width: "100%"
                    //   }}>
                    //   <AGCustomTable
                    //     loading={loading}
                    //     columns={columns}
                    //     data={taskList}
                    //     pageIndex={pageIndex}
                    //     pageSize={pageSize}
                    //     pagination={true}
                    //     searchBoxClass="mb-2"
                    //     isSearchable={true}
                    //     isSortable={false}
                    //     fetchData={fetchData}
                    //     sizePerPageList={sizePerPageList}
                    //     totalRecords={totalRecords}
                    //     isCustomActionButton={true}
                    //     addButton={<AddTaskButton />}
                    //     filterControls={<FilterControls />}
                    //   />

                    // </div>


                  }
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {show && (
        <AddSubAdminTask
          show={show}
          task={task}
          onHide={onCloseModal}
          onSubmit={onHandleSubmit}
        />
      )}

      {showDetails && (
        <ShowSubAdminTask
          show={showDetails}
          task={task}
          onHide={onCloseModal}
          onSubmit={onHandleSubmit}
        />
      )}
    </>
  );
};

export default SubAdminTask;
