import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Card, Form, Button, Accordion, AccordionContext, useAccordionButton } from "react-bootstrap";
import FileUploader from "../../../components/FileUploader";
import { isConstructorDeclaration } from "typescript";
import notify from "../../../components/Notification/notify";
import NotificationContainer from "../../../components/Notification/NotificationContainer";
import { addProductAction, fetchCategoryAction } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import ProductReducer from "../../../redux/product/reducers";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import Select from "react-select/src/Select";

const AddProductForm = () => {
  const [categoryList, setCategoryList] = useState<any>([]);
  const [productImages, setProductImages] = useState<any>([]);
  const [headerColors, setHeaderColors] = useState<any>([]);
  const [color, setColor] = useState<any>([]);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  // * product category
  const { categoryDetails, loading, error, successMessage, ErrorAddProductData, AddProductData } = useSelector(
    (state: RootState) => ({
      categoryDetails: state.CategoryReducer.categoryDetails,
      loading: state.CategoryReducer.loading,
      error: state.CategoryReducer.error,
      successMessage: state.CategoryReducer.successMessage,
      ErrorAddProductData: state.ProductReducer.ErrorAddProductData,
      AddProductData: state.ProductReducer.AddProductData,
    })
  );
  useEffect(() => {
    dispatch(fetchCategoryAction());
  }, [dispatch]);

  useEffect(() => {
    if (
      categoryDetails?.statusCode === 200 &&
      categoryDetails?.data !== undefined
    ) {
      setCategoryList(categoryDetails?.data);
    }
  }, [categoryDetails]);

  // State to hold form field values
  const [productData, setProductData] = useState<any>({
    name: "",
    attributes: [],
    combinations: [],
  });

  // Function to handle adding attributes
  const handleAddAttribute = (attributeName: any) => {
    const newAttribute = {
      name: attributeName,
      values: [],
    };
    setProductData((prevData: any) => ({
      ...prevData,
      attributes: [...prevData.attributes, newAttribute],
    }));
  };

  // Function to handle adding attribute values
  const handleAddAttributeValue = (
    attrName: any,
    attributeIndex: any,
    value: any
  ) => {
    setProductData((prevData: any) => ({
      ...prevData,
      attributes: prevData.attributes.map((attribute: any, index: any) =>
        attribute.name === attrName
          ? {
            ...attribute,
            values: [...attribute.values, value],
            inputValue: "",
          }
          : attribute
      ),
    }));
  };

  // Function to handle removing an attribute value
  const handleRemoveAttributeValue = (
    attrName: any,
    attributeIndex: any,
    valueIndex: any
  ) => {
    // index === attributeIndex
    setProductData((prevData: any) => ({
      ...prevData,
      attributes: prevData.attributes.map((attribute: any, index: any) =>
        attribute.name === attrName
          ? {
            ...attribute,
            values: attribute.values.filter(
              (_: any, idx: any) => idx !== valueIndex
            ),
          }
          : attribute
      ),
    }));
  };

  // Function to handle generating all possible combinations
  const handleGenerateCombinations = () => {
    if (productData.attributes.length > 0 && productData.name) {
      const combinations = cartesianProduct(
        ...productData.attributes.map((attr: any) => attr)
      );
      const output_temp = generateCombinationsData(combinations);
      setProductData((prevData: any) => ({
        ...prevData,
        combinations: output_temp.map((combination: any) => ({
          attributes: combination,
          description: "",
          unit_price: "",
          cost_price: "",
          product_category_id: "",
          product_subcategory_id: "",
          photo_url: [],
          stock: 1
        })),
      }));
    } else {
      // notify error
      notify("Please enter product data.", "error");
    }
  };

  const generateCombinationsData = (input: any[]): Record<string, string>[] => {
    const combinations: Record<string, string>[] = [];
    const backtrack = (index: number, current: Record<string, string>) => {
      if (index === input.length) {
        combinations.push({ ...current });
        return;
      }

      const attribute = input[index];
      for (const value of attribute.value) {
        current[attribute.name] = value;
        backtrack(index + 1, current);
      }
    };

    backtrack(0, {});
    return combinations;
  };

  // Function to calculate the Cartesian product of arrays
  const cartesianProduct = (...arrays: any) => {
    let _objArr: any = [];
    arrays.map((acc: any, curr: any) => {
      _objArr.push({
        name: acc.name,
        value: acc.values,
      });
    });
    return _objArr;
  };

  // Function to check if a value is blank, undefined, or empty array
  function isValueEmpty(value: any) {
    return value === "" || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
  }

  // Function to check if any value in the object is empty
  function hasEmptyValue(obj: any) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (isValueEmpty(obj[key])) {
          return true; // Found an empty value
        }
      }
    }
    return false; // No empty value found
  }

  // Function to handle form submission
  const handleSubmit = (event: any) => {
    // event.preventDefault();
    // Check if the product has any empty values
    if (hasEmptyValue(productData.combinations[0])) {
      notify("insufficient Product Details.", "error");
    } else {
      dispatch(addProductAction(productData));
    }
  };
  useEffect(() => {
    if (AddProductData?.statusCode === 200) {
      navigate("/admin/products");
    }
  }, [AddProductData, dispatch, productData])

  // Function to reset the form
  const handleResetForm = () => {
    setProductData({
      name: "",
      attributes: [],
      combinations: [],
    });
  };

  //Category HandleChange 
  const handleCategoryChange = (index: any, categoryId: any) => {
    // Find the category object based on the selected ID
    const selectedCategory = categoryList.find((cat: any) => cat.product_category_id === categoryId);

    // Update product category ID in the combinations array
    setProductData((prevData: any) => ({
      ...prevData,
      combinations: prevData.combinations.map((combination: any, i: any) => {
        if (i === index) {
          return {
            ...combination,
            product_category_id: categoryId
          };
        }
        return combination;
      })
    }));

    // Reset subcategory ID
    setProductData((prevData: any) => ({
      ...prevData,
      combinations: prevData.combinations.map((combination: any, i: any) => {
        if (i === index) {
          return {
            ...combination,
            product_subcategory_id: null // Assuming you have a null or empty value for no selection
          };
        }
        return combination;
      })
    }));
  };

  // SubCategory HandleChange
  const handleSubCategoryChange = (index: any, subcategoryId: any) => {
    setProductData((prevData: any) => ({
      ...prevData,
      combinations: prevData.combinations.map((combination: any, i: any) => {
        if (i === index) {
          return {
            ...combination,
            product_subcategory_id: subcategoryId
          };
        }
        return combination;
      })
    }));
  };

  // *************************
  interface TabContentType {
    id: number;
    title: string;
    icon?: string;
    text: string;
  }
  interface CustomToggleProps {
    children: any;
    eventKey: string;
    containerClass: string;
    linkClass: string;
    callback?: any;
  }

  const CustomToggle = ({
    children,
    eventKey,
    containerClass,
    linkClass,
    callback,
  }: CustomToggleProps) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
      <h5 className={containerClass}>
        <Link
          to="#"
          className={classNames(linkClass, {
            collapsed: !isCurrentEventKey,
          })}
          onClick={decoratedOnClick}
        >
          {children}
        </Link>
      </h5>
    );
  };

  // delete combination
  let handleRemoveCombination = (index: any) => {
    if (productData.combinations.length > 1) {
      const updatedCombinations = [...productData.combinations];
      updatedCombinations.splice(index, 1);
      setProductData({
        ...productData,
        combinations: updatedCombinations
      });
    } else {
      // reset form
      handleResetForm()
    }
  }

  // Save combination
  let handleSaveCombination = (item: any, index: any) => {
    // setHeaderColors()
    const indexExists = color.some((item: any) => item.index === index);
    delete item.product_subcategory_id
    const hasBlankValues = Object.values(item).some((value: any) => value === "" || value === null || value === undefined || value.length === 0);
    if (hasBlankValues) {
      notify("insufficient Product Details.", "error")
    }
    const newItem = {
      color: hasBlankValues ? false : true,
      index: index
    };
    if (indexExists) {
      const updatedColor = color.map((item: any) => {
        if (item.index === index) {
          return { ...item, color: newItem.color };
        }
        return item;
      });
      setColor(updatedColor);
    } else {
      setColor([...color, newItem]);
    }
  };

  const CustomAccordion2 = ({
    key,
    item,
    index,
  }: {
    key: any;
    item: any;
    index: number;
  }) => {
    return (
      <>
        <Card className="mb-0">
          <Card.Header className="bg-success">
            <CustomToggle
              eventKey={String(index)}
              containerClass="m-0 position-relative"
              linkClass="custom-accordion-title text-reset d-block"
            >
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <p className="m-0 me-3">
                    Color :
                    {item?.attributes?.Color || "-"}
                  </p>
                  <p className="m-0 me-3">
                    Material :
                    {item?.attributes?.Material || "-"}
                  </p>
                  <p className="m-0 me-3">
                    Shape :
                    {item?.attributes?.Shape || "-"}
                  </p>
                  <p className="m-0 me-3">
                    Size :
                    {item?.attributes?.Size || "-"}
                  </p>
                </div>
                <div className="">
                  {/* <i className="mdi mdi-delete me-3"></i> */}
                  <i className="mdi mdi-chevron-down accordion-arrow"></i>
                </div>
              </div>
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey={String(index)}>
            <Card.Body>
              <div className="">
                <Row>
                  <Col lg={6}>
                    <div className="">

                      {/* ------ */}
                      <Row className="mb-3">
                        {/* <Col>
                          <div className="">
                            <p>Product Code</p>
                            <h4 className="badge bg-warning text-white p-1">
                              AUTO GEN
                            </h4>
                          </div>
                        </Col> */}

                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formGridVariant"
                          >
                            <Form.Label> Variant </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter variant"
                              value={item.product_variant}
                              onChange={(e: any) =>
                                setProductData(
                                  (prevData: any) => {
                                    const updatedCombinations = [
                                      ...prevData.combinations,
                                    ];
                                    updatedCombinations[
                                      index
                                    ].product_variant =
                                      e.target.value;
                                    return {
                                      ...prevData,
                                      combinations:
                                        updatedCombinations,
                                    };
                                  }
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <Form.Group
                            as={Col}
                            controlId="formGridPrice"
                          >
                            <Form.Label>Unit Price</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="₹ 99"
                              value={item.unit_price}
                              min="1"
                              onChange={(e) =>
                                setProductData(
                                  (prevData: any) => {
                                    const updatedCombinations = [
                                      ...prevData.combinations,
                                    ];
                                    let unit_price = parseFloat(
                                      e.target.value
                                    );
                                    updatedCombinations[
                                      index
                                    ].unit_price = isNaN(
                                      unit_price
                                    )
                                        ? 0
                                        : unit_price;
                                    return {
                                      ...prevData,
                                      combinations:
                                        updatedCombinations,
                                    };
                                  }
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            as={Col}
                            controlId="formGridPrice"
                          >
                            <Form.Label>Cost Price</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="₹ 99"
                              min="1"
                              value={item.cost_price}
                              onChange={(e) =>
                                setProductData(
                                  (prevData: any) => {
                                    const updatedCombinations = [
                                      ...prevData.combinations,
                                    ];
                                    let cost_price = parseFloat(
                                      e.target.value
                                    );
                                    updatedCombinations[
                                      index
                                    ].cost_price = isNaN(
                                      cost_price
                                    )
                                        ? 0
                                        : cost_price;
                                    return {
                                      ...prevData,
                                      combinations:
                                        updatedCombinations,
                                    };
                                  }
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            as={Col}
                            controlId="formGridPrice"
                          >
                            <Form.Label>RRR Price</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="₹ 99"
                              min="1"
                              value={item.rrr_price}
                              onChange={(e) =>
                                setProductData(
                                  (prevData: any) => {
                                    const updatedCombinations = [
                                      ...prevData.combinations,
                                    ];
                                    let rrr_price = parseFloat(
                                      e.target.value
                                    );
                                    updatedCombinations[
                                      index
                                    ].rrr_price = isNaN(rrr_price)
                                        ? 0
                                        : rrr_price;
                                    return {
                                      ...prevData,
                                      combinations:
                                        updatedCombinations,
                                    };
                                  }
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Form.Group
                        className="mb-3"
                        controlId="formGridAddress1"
                      >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Enter description"
                          rows={5}
                          value={item.description}
                          onChange={(e: any) =>
                            setProductData((prevData: any) => {
                              const updatedCombinations = [
                                ...prevData.combinations,
                              ];
                              updatedCombinations[
                                index
                              ].description = e.target.value;
                              return {
                                ...prevData,
                                combinations: updatedCombinations,
                              };
                            })
                          }
                        />
                      </Form.Group>

                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          controlId={`category_${index}`}
                        >
                          <Form.Label>Category</Form.Label>
                          <Form.Select
                            defaultValue="Choose..."
                            onChange={(e: any) =>
                              handleCategoryChange(
                                index,
                                e.target.value
                              )
                            }
                          >
                            <option disabled>Choose...</option>
                            {categoryList.map((category: any) => (
                              <option
                                key={category.product_category_id}
                                value={
                                  category.product_category_id
                                }
                              >
                                {category.category_name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          controlId={`subcategory_${index}`}
                        >
                          <Form.Label>Sub Category</Form.Label>
                          <Form.Select
                            defaultValue="Choose..."
                            onChange={(e: any) =>
                              handleSubCategoryChange(
                                index,
                                e.target.value
                              )
                            }
                          >
                            <option disabled>Choose...</option>
                            {productData.combinations[index]
                              ?.product_category_id &&
                              categoryList
                                .find(
                                  (cat: any) =>
                                    cat.product_category_id ===
                                    productData.combinations[
                                      index
                                    ].product_category_id
                                )
                                ?.wm_product_subcategories.map(
                                  (subcategory: any) => (
                                    <option
                                      key={
                                        subcategory.product_subcategory_id
                                      }
                                      value={
                                        subcategory.product_subcategory_id
                                      }
                                    >
                                      {
                                        subcategory.subcategory_name
                                      }
                                    </option>
                                  )
                                )}
                          </Form.Select>
                        </Form.Group>

                      </Row>

                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formGridStock"
                          >
                            <Form.Label>Stock</Form.Label>
                            <Form.Control
                              type="number"
                              min={1}
                              name="stock"
                              placeholder="stock Qty"
                              style={{ width: "120px" }}
                              value={
                                item && item.stock > 0
                                  ? item.stock
                                  : 1
                              }
                              onChange={(e: any) => {
                                setProductData(
                                  (prevData: any) => {
                                    const updatedCombinations = [
                                      ...prevData.combinations,
                                    ];
                                    updatedCombinations[
                                      index
                                    ].stock = e.target.value;
                                    return {
                                      ...prevData,
                                      combinations:
                                        updatedCombinations,
                                    };
                                  }
                                );
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <Card>
                      <Card.Body>
                        <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                          Product Images
                        </h5>
                        {/* <FileUploader
                          setProductImages={setProductImages}
                          setProductData={setProductData}
                          index={index}
                        /> */}
                        <FileUploader
                          setProductImages={setProductImages}
                          setProductData={setProductData}
                          index={index}
                        // product_name={productData}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="">
                      <button type="button" className="btn btn-success me-2" onClick={() => handleSaveCombination(item, index)}>
                        <i className="mdi mdi-content-save"></i> Save
                      </button>
                      <button type="button" className="btn btn-danger" onClick={() => handleRemoveCombination(index)}>
                        <i className="mdi mdi-trash-can"></i> Remove
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>

            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </>
    );
  };

  return (
    <div>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="d-flex flex-wrap">
                <label className="mb-2" htmlFor="name">
                  Product Name
                </label>
                <input
                  className="form-control mb-3"
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter product name"
                  value={productData.name}
                  onChange={(e) =>
                    setProductData({ ...productData, name: e.target.value })
                  }
                />
              </div>
              <div className="mb-3">
                <label className="mb-2">Attributes</label>
                <Row>
                  <Col lg={8} md={8} sm={6} xs={6}>
                    <Row>
                      <Col lg={3}>
                        <Card>
                          <Card.Body>
                            <div className="d-flex flex-wrap">
                              <div>
                                {/* <h3>Add Attributes:</h3> */}
                                <button
                                  className="btn btn-primary ms-2"
                                  type="button"
                                  onClick={() => handleAddAttribute("Color")}
                                  disabled={
                                    productData.combinations.length > 0 ||
                                    productData.attributes.find(
                                      (i: any) => i.name === "Color"
                                    )
                                  }
                                >
                                  Add Color
                                </button>
                                {/* You can add more buttons for other attributes */}
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg={3}>
                        <Card>
                          <Card.Body>
                            <div className="d-flex flex-wrap">
                              <div>
                                <button
                                  className="btn btn-primary ms-2"
                                  type="button"
                                  onClick={() => handleAddAttribute("Material")}
                                  disabled={
                                    productData.combinations.length > 0 ||
                                    productData.attributes.find(
                                      (i: any) => i.name === "Material"
                                    )
                                  }
                                >
                                  Add Material
                                </button>

                                {/* You can add more buttons for other attributes */}
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg={3}>
                        <Card>
                          <Card.Body>
                            <div className="d-flex flex-wrap">
                              <div>
                                <button
                                  className="btn btn-primary ms-2"
                                  type="button"
                                  onClick={() => handleAddAttribute("Shape")}
                                  disabled={
                                    productData.combinations.length > 0 ||
                                    productData.attributes.find(
                                      (i: any) => i.name === "Shape"
                                    )
                                  }
                                >
                                  Add Shape
                                </button>

                                {/* You can add more buttons for other attributes */}
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg={3}>
                        <Card>
                          <Card.Body>
                            <div className="d-flex flex-wrap">
                              <div>
                                <button
                                  className="btn btn-primary ms-2"
                                  type="button"
                                  onClick={() => handleAddAttribute("Size")}
                                  disabled={
                                    productData.combinations.length > 0 ||
                                    productData.attributes.find(
                                      (i: any) => i.name === "Size"
                                    )
                                  }
                                >
                                  Add Size
                                </button>
                                {/* You can add more buttons for other attributes */}
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={4} md={4} sm={6} xs={6}>
                    <Card>
                      <Card.Body>
                        <div className="d-flex justify-content-around">
                          <div className="">
                            <button
                              className="btn btn-success ms-2"
                              type="button"
                              onClick={handleGenerateCombinations}
                              disabled={productData.combinations.length > 0}
                            >
                              Generate Form
                            </button>
                          </div>
                          <div className="">
                            <button
                              className="btn btn-danger ms-2"
                              type="button"
                              onClick={handleResetForm}
                            >
                              Reset Form
                            </button>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row
        style={
          productData.combinations.length > 0
            ? { pointerEvents: "none", opacity: "0.7" }
            : {}
        }
      >
        {/* Listing */}
        {productData.attributes.length <= 0 ? (
          <Col lg={12}>
            <Card>
              <Card.Body>
                <div className="text-center">
                  <h3> No Items </h3>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ) : (

          <Col lg={12}>
            <div className="  bg-white">
              <Row>
                <Col lg={3} md={3}>
                  <Card className="my-2">
                    <Card.Body>
                      <h3>Color</h3>

                      {productData.attributes
                        .filter((ele: any) => ele.name === "Color")
                        .map((attribute: any, index: any) => (
                          <div key={index}>
                            {attribute.values.map((value: any, idx: any) => (
                              <div key={idx} className="my-3">
                                <span className="me-3">{value}</span>
                                {/* <button
                              className="btn btn-sm btn-danger"
                              type="button"
                              onClick={() =>
                                handleRemoveAttributeValue("Color", index, idx)
                              }
                            >
                              Remove
                            </button> */}
                                <Button
                                  key={index}
                                  variant={"danger"}
                                  className=" waves-effect waves-light p-1" // rounded-pill
                                  onClick={() =>
                                    handleRemoveAttributeValue("Color", index, idx)
                                  }
                                >
                                  <i className="mdi mdi-close"></i>
                                  {/* <span className="btn-label">
                              </span> */}
                                  {/* Remove */}
                                </Button>
                              </div>
                            ))}

                            <Row>
                              <Col>
                                {/* color input */}
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder={`Enter ${attribute.name}`}
                                  value={
                                    productData.attributes.find(
                                      (i: any, j: any) => i.name === "Color"
                                    ).inputValue
                                  }
                                  onChange={(e) => {
                                    setProductData((prevData: any) => {
                                      const updatedAttributes: any = [
                                        ...prevData.attributes,
                                      ];
                                      let isFind = updatedAttributes.map(
                                        (i: any) => {
                                          if (i.name == "Color") {
                                            return {
                                              ...i,
                                              inputValue: e.target.value,
                                            };
                                          } else {
                                            return {
                                              ...i,
                                            };
                                          }
                                        }
                                      );
                                      return {
                                        ...prevData,
                                        attributes: isFind,
                                      };
                                    });
                                  }}
                                />
                              </Col>
                              <Col>
                                <button
                                  className="btn btn-success"
                                  type="button"
                                  onClick={() =>
                                    handleAddAttributeValue(
                                      "Color",
                                      index,
                                      productData.attributes.find(
                                        (i: any, j: any) => i.name === "Color"
                                      ).inputValue
                                    )
                                  }
                                  disabled={
                                    !productData.attributes.find(
                                      (i: any, j: any) => i.name === "Color"
                                    ).inputValue
                                  }
                                >
                                  Add {attribute.name}
                                </button>
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={3} md={3}>
                  <Card className="my-2">
                    {/* for Material */}
                    <Card.Body>
                      <h3>Material</h3>
                      {productData.attributes
                        .filter((ele: any) => ele.name === "Material")
                        .map((attribute: any, index: any) => (
                          <div key={index}>
                            {attribute.values.map((value: any, idx: any) => (
                              <div key={idx} className="my-3">
                                <span className="me-3">{value}</span>
                                <button
                                  className="btn btn-sm btn-danger p-1"
                                  type="button"
                                  onClick={() =>
                                    handleRemoveAttributeValue(
                                      "Material",
                                      index,
                                      idx
                                    )
                                  }
                                >
                                  <i className="mdi mdi-close"></i>
                                </button>
                              </div>
                            ))}

                            <Row>
                              <Col>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder={`Enter ${attribute.name}`}
                                  value={
                                    productData.attributes.find(
                                      (i: any, j: any) => i.name === "Material"
                                    ).inputValue
                                  }
                                  onChange={(e) =>
                                    setProductData((prevData: any) => {
                                      const updatedAttributes = [
                                        ...prevData.attributes,
                                      ];

                                      let isFind = updatedAttributes.map(
                                        (i: any) => {
                                          if (i.name == "Material") {
                                            return {
                                              ...i,
                                              inputValue: e.target.value,
                                            };
                                          } else {
                                            return {
                                              ...i,
                                            };
                                          }
                                        }
                                      );
                                      return {
                                        ...prevData,
                                        attributes: isFind,
                                      };
                                    })
                                  }
                                />
                              </Col>
                              <Col>
                                <button
                                  className="btn btn-success"
                                  type="button"
                                  onClick={() =>
                                    handleAddAttributeValue(
                                      "Material",
                                      index,
                                      productData.attributes.find(
                                        (i: any, j: any) => i.name === "Material"
                                      ).inputValue
                                    )
                                  }
                                  disabled={
                                    !productData.attributes.find(
                                      (i: any, j: any) => i.name === "Material"
                                    ).inputValue
                                  }
                                >
                                  Add {attribute.name}
                                </button>
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={3} md={3}>
                  {/* for Shape */}
                  <Card className="my-2">
                    <Card.Body>
                      <h3>Shape</h3>
                      {productData.attributes
                        .filter((ele: any) => ele.name === "Shape")
                        .map((attribute: any, index: any) => (
                          <div key={index}>
                            {attribute.values.map((value: any, idx: any) => (
                              <div key={idx} className="my-3">
                                <span className="me-3">{value}</span>
                                <button
                                  className="btn btn-sm btn-danger p-1"
                                  type="button"
                                  onClick={() =>
                                    handleRemoveAttributeValue("Shape", index, idx)
                                  }
                                >
                                  <i className="mdi mdi-close"></i>
                                </button>
                              </div>
                            ))}

                            <Row>
                              <Col>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder={`Enter ${attribute.name}`}
                                  value={
                                    productData.attributes.find(
                                      (i: any, j: any) => i.name === "Shape"
                                    ).inputValue
                                  }
                                  onChange={(e) =>
                                    setProductData((prevData: any) => {
                                      const updatedAttributes = [
                                        ...prevData.attributes,
                                      ];
                                      let isFind = updatedAttributes.map(
                                        (i: any) => {
                                          if (i.name == "Shape") {
                                            return {
                                              ...i,
                                              inputValue: e.target.value,
                                            };
                                          } else {
                                            return {
                                              ...i,
                                            };
                                          }
                                        }
                                      );
                                      return {
                                        ...prevData,
                                        attributes: isFind,
                                      };
                                    })
                                  }
                                />
                              </Col>
                              <Col>
                                <button
                                  className="btn btn-success"
                                  type="button"
                                  onClick={() =>
                                    handleAddAttributeValue(
                                      "Shape",
                                      index,
                                      productData.attributes.find(
                                        (i: any, j: any) => i.name === "Shape"
                                      ).inputValue
                                    )
                                  }
                                  disabled={
                                    !productData.attributes.find(
                                      (i: any, j: any) => i.name === "Shape"
                                    ).inputValue
                                  }
                                >
                                  Add {attribute.name}
                                </button>
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={3} md={3}>
                  {/* for Size */}
                  <Card className="my-2">
                    <Card.Body>
                      <h3>Size</h3>
                      {productData.attributes
                        .filter((ele: any) => ele.name === "Size")
                        .map((attribute: any, index: any) => (
                          <div key={index}>
                            {attribute.values.map((value: any, idx: any) => (
                              <div key={idx} className="my-3">
                                <span className="me-3">{value}</span>
                                <button
                                  className="btn btn-sm btn-danger p-1"
                                  type="button"
                                  onClick={() =>
                                    handleRemoveAttributeValue("Size", index, idx)
                                  }
                                >
                                  <i className="mdi mdi-close"></i>
                                </button>
                              </div>
                            ))}

                            <Row>
                              <Col>
                                {/* form button */}
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder={`Enter ${attribute.name}`}
                                  value={
                                    productData.attributes.find(
                                      (i: any, j: any) => i.name === "Size"
                                    ).inputValue
                                  }
                                  onChange={(e) =>
                                    setProductData((prevData: any) => {
                                      const updatedAttributes = [
                                        ...prevData.attributes,
                                      ];
                                      let isFind = updatedAttributes.map(
                                        (i: any) => {
                                          if (i.name == "Size") {
                                            return {
                                              ...i,
                                              inputValue: e.target.value,
                                            };
                                          } else {
                                            return {
                                              ...i,
                                            };
                                          }
                                        }
                                      );
                                      return {
                                        ...prevData,
                                        attributes: isFind,
                                      };
                                    })
                                  }
                                />
                              </Col>
                              <Col>
                                {/* form button */}
                                <button
                                  className="btn btn-success"
                                  type="button"
                                  onClick={() =>
                                    handleAddAttributeValue(
                                      "Size",
                                      index,
                                      productData.attributes.find(
                                        (i: any, j: any) => i.name === "Size"
                                      ).inputValue
                                    )
                                  }
                                  disabled={
                                    !productData.attributes.find(
                                      (i: any, j: any) => i.name === "Size"
                                    ).inputValue
                                  }
                                >
                                  Add {attribute.name}
                                </button>
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>

        )}
      </Row>

      {/* <h2>Add Product</h2> */}
      <form>
        {productData.combinations.length > 0 && (
          <div>
            <div className="my-3">
              <Row>
                <Col lg={12} className="p-0">
                  <Card>
                    <Card.Body>
                      <div className="my-2">
                        <h3>All Possible Combinations : <span className=""> {productData.combinations.length ?? 0} </span></h3>
                      </div>
                      <div className="">
                        <div className="">
                          <Accordion
                            defaultActiveKey="0"
                            id="accordion"
                            className="custom-accordion"
                          >
                            {productData.combinations.map(
                              (item: any, index: any) => (
                                <div key={index} className="my-2 d-flex bd-highlight">
                                  {/* Main wrapper */}
                                  {/* <CustomAccordion2 key={index} item={combination} index={index} /> */}
                                  <div className="p-1 w-100 bd-highlight">
                                    <Card className="mb-0">
                                      <Card.Header
                                        style={{
                                          backgroundColor: color.length > 0 && color.some((item: any) => item.color && item.index === index) ? "#198754" : "#050A30",
                                          color: "white",
                                          // padding: "8px" // Adjust padding as needed
                                        }}
                                        className="py-2"
                                      >
                                        <CustomToggle
                                          eventKey={String(index)}
                                          containerClass="m-0 position-relative"
                                          linkClass="custom-accordion-title text-reset d-block"
                                        >
                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex text-white">
                                              <p className="m-0 me-3">
                                                Color :
                                                {item?.attributes?.Color || "-"}
                                              </p>
                                              <p className="m-0 me-3">
                                                Material :
                                                {item?.attributes?.Material || "-"}
                                              </p>
                                              <p className="m-0 me-3">
                                                Shape :
                                                {item?.attributes?.Shape || "-"}
                                              </p>
                                              <p className="m-0 me-3">
                                                Size :
                                                {item?.attributes?.Size || "-"}
                                              </p>
                                            </div>
                                            <div className="">
                                              {/* <i className="mdi mdi-delete me-3"></i> */}
                                              <i className="mdi mdi-chevron-down accordion-arrow text-white"></i>
                                            </div>
                                          </div>
                                        </CustomToggle>
                                      </Card.Header>
                                      <Accordion.Collapse eventKey={String(index)}>

                                        <Card.Body>
                                          <div className="text-dark">
                                            <Row>
                                              <Col lg={6}>
                                                <div className="">
                                                  <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                                                    Product Details
                                                  </h5>

                                                  {/* ------ */}
                                                  <Row className="mb-3">
                                                    {/* <Col>
                                                      <div className="">
                                                        <p>Product Code</p>
                                                        <h4 className="badge bg-warning text-white p-1">
                                                          AUTO GEN
                                                        </h4>
                                                      </div>
                                                    </Col> */}

                                                    <Col>
                                                      <Form.Group
                                                        className="mb-3"
                                                        controlId="formGridVariant"
                                                      >
                                                        <Form.Label> Variant </Form.Label>
                                                        <Form.Control
                                                          type="text"
                                                          placeholder="Enter variant"
                                                          value={item.product_variant}
                                                          onChange={(e: any) =>
                                                            setProductData(
                                                              (prevData: any) => {
                                                                const updatedCombinations = [
                                                                  ...prevData.combinations,
                                                                ];
                                                                updatedCombinations[
                                                                  index
                                                                ].product_variant =
                                                                  e.target.value;
                                                                return {
                                                                  ...prevData,
                                                                  combinations:
                                                                    updatedCombinations,
                                                                };
                                                              }
                                                            )
                                                          }
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                  </Row>
                                                  <Row className="mb-3">
                                                    <Col>
                                                      <Form.Group
                                                        as={Col}
                                                        controlId="formGridPrice"
                                                      >
                                                        <Form.Label>Unit Price</Form.Label>
                                                        <Form.Control
                                                          type="number"
                                                          placeholder="₹ 99"
                                                          value={item.unit_price}
                                                          min="1"
                                                          onChange={(e) => {
                                                            const value = parseFloat(e.target.value);
                                                            if (isNaN(value) || value < 1) {
                                                              notify('Enter valid price.', 'error');
                                                            } else {
                                                              setProductData(
                                                                (prevData: any) => {
                                                                  const updatedCombinations = [
                                                                    ...prevData.combinations,
                                                                  ];
                                                                  let unit_price = parseFloat(
                                                                    e.target.value
                                                                  );
                                                                  updatedCombinations[
                                                                    index
                                                                  ].unit_price = isNaN(
                                                                    unit_price
                                                                  )
                                                                      ? 0
                                                                      : unit_price;
                                                                  return {
                                                                    ...prevData,
                                                                    combinations:
                                                                      updatedCombinations,
                                                                  };
                                                                }
                                                              )
                                                            }
                                                          }
                                                          }
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                    <Col>
                                                      <Form.Group
                                                        as={Col}
                                                        controlId="formGridPrice"
                                                      >
                                                        <Form.Label>Cost Price</Form.Label>
                                                        <Form.Control
                                                          type="number"
                                                          placeholder="₹ 99"
                                                          min="1"
                                                          value={item.cost_price}
                                                          onChange={(e) => {
                                                            const value = parseFloat(e.target.value);
                                                            if (isNaN(value) || value < 1) {
                                                              notify('Enter valid price.', 'error');
                                                            } else {
                                                              setProductData(
                                                                (prevData: any) => {
                                                                  const updatedCombinations = [
                                                                    ...prevData.combinations,
                                                                  ];
                                                                  let cost_price = parseFloat(
                                                                    e.target.value
                                                                  );
                                                                  updatedCombinations[
                                                                    index
                                                                  ].cost_price = isNaN(
                                                                    cost_price
                                                                  )
                                                                      ? 0
                                                                      : cost_price;
                                                                  return {
                                                                    ...prevData,
                                                                    combinations:
                                                                      updatedCombinations,
                                                                  };
                                                                }
                                                              )
                                                            }
                                                          }}
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                    <Col>
                                                      <Form.Group
                                                        as={Col}
                                                        controlId="formGridPrice"
                                                      >
                                                        <Form.Label>RRR Price</Form.Label>
                                                        <Form.Control
                                                          type="number"
                                                          placeholder="₹ 99"
                                                          min="1"
                                                          value={item.rrr_price}
                                                          onChange={(e) => {
                                                            const value = parseFloat(e.target.value);
                                                            if (isNaN(value) || value < 1) {
                                                              notify('Enter valid price.', 'error');
                                                            } else {
                                                              setProductData(
                                                                (prevData: any) => {
                                                                  const updatedCombinations = [
                                                                    ...prevData.combinations,
                                                                  ];
                                                                  let rrr_price = parseFloat(
                                                                    e.target.value
                                                                  );
                                                                  updatedCombinations[
                                                                    index
                                                                  ].rrr_price = isNaN(rrr_price)
                                                                      ? 0
                                                                      : rrr_price;
                                                                  return {
                                                                    ...prevData,
                                                                    combinations:
                                                                      updatedCombinations,
                                                                  };
                                                                }
                                                              )
                                                            }
                                                          }}
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                  </Row>

                                                  <Form.Group
                                                    className="mb-3"
                                                    controlId="formGridAddress1"
                                                  >
                                                    <Form.Label>Description</Form.Label>
                                                    <Form.Control
                                                      as="textarea"
                                                      placeholder="Enter description"
                                                      rows={5}
                                                      value={item.description}
                                                      onChange={(e: any) =>
                                                        setProductData((prevData: any) => {
                                                          const updatedCombinations = [
                                                            ...prevData.combinations,
                                                          ];
                                                          updatedCombinations[
                                                            index
                                                          ].description = e.target.value;
                                                          return {
                                                            ...prevData,
                                                            combinations: updatedCombinations,
                                                          };
                                                        })
                                                      }
                                                    />
                                                  </Form.Group>

                                                  <Row className="mb-3">
                                                    <Form.Group
                                                      as={Col}
                                                      controlId={`category_${index}`}
                                                    >
                                                      <Form.Label>Category</Form.Label>
                                                      <Form.Select
                                                        defaultValue="Choose..."
                                                        onChange={(e: any) =>
                                                          handleCategoryChange(
                                                            index,
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option disabled>Choose...</option>
                                                        {categoryList.map((category: any) => (
                                                          <option
                                                            key={category.product_category_id}
                                                            value={
                                                              category.product_category_id
                                                            }
                                                          >
                                                            {category.category_name}
                                                          </option>
                                                        ))}
                                                      </Form.Select>
                                                    </Form.Group>

                                                    <Form.Group
                                                      as={Col}
                                                      controlId={`subcategory_${index}`}
                                                    >
                                                      <Form.Label>Sub Category</Form.Label>
                                                      <Form.Select
                                                        defaultValue="Choose..."
                                                        onChange={(e: any) =>
                                                          handleSubCategoryChange(
                                                            index,
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option disabled>Choose...</option>
                                                        {productData.combinations[index]
                                                          ?.product_category_id &&
                                                          categoryList
                                                            .find(
                                                              (cat: any) =>
                                                                cat.product_category_id ===
                                                                productData.combinations[
                                                                  index
                                                                ].product_category_id
                                                            )
                                                            ?.wm_product_subcategories.map(
                                                              (subcategory: any) => (
                                                                <option
                                                                  key={
                                                                    subcategory.product_subcategory_id
                                                                  }
                                                                  value={
                                                                    subcategory.product_subcategory_id
                                                                  }
                                                                >
                                                                  {
                                                                    subcategory.subcategory_name
                                                                  }
                                                                </option>
                                                              )
                                                            )}
                                                      </Form.Select>
                                                    </Form.Group>

                                                  </Row>

                                                  <Row>
                                                    <Col>
                                                      <Form.Group
                                                        className="mb-3"
                                                        controlId="formGridStock"
                                                      >
                                                        <Form.Label>Stock</Form.Label>
                                                        <Form.Control
                                                          type="number"
                                                          min={1}
                                                          name="stock"
                                                          placeholder="stock Qty"
                                                          style={{ width: "120px" }}
                                                          value={
                                                            item && item.stock > 0
                                                              ? item.stock
                                                              : 1
                                                          }
                                                          onChange={(e: any) => {
                                                            const value = parseInt(e.target.value, 10);
                                                            if (isNaN(value) || value < 1) {
                                                              notify('There should be at least one quantity.', 'error');
                                                            } else {
                                                              setProductData(
                                                                (prevData: any) => {
                                                                  const updatedCombinations = [
                                                                    ...prevData.combinations,
                                                                  ];
                                                                  updatedCombinations[
                                                                    index
                                                                  ].stock = e.target.value;
                                                                  return {
                                                                    ...prevData,
                                                                    combinations:
                                                                      updatedCombinations,
                                                                  };
                                                                }
                                                              );
                                                            }


                                                          }}
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Col>
                                              <Col lg={6}>
                                                <Card>
                                                  <Card.Body className="p-0">
                                                    <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                                                      Product Images
                                                    </h5>
                                                    {/* <FileUploader
                                                      setProductImages={setProductImages}
                                                      setProductData={setProductData}
                                                      index={index}
                                                      product_name={productData}
                                                    /> */}
                                                    <FileUploader
                                                      setProductImages={setProductImages}
                                                      setProductData={setProductData}
                                                      index={index}
                                                      product_name={productData}
                                                    />
                                                  </Card.Body>
                                                </Card>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <div className="">
                                                  <button type="button" className="btn btn-success me-2" onClick={() => handleSaveCombination(item, index)}>
                                                    <i className="mdi mdi-content-save"></i> Save
                                                  </button>
                                                  {/* <button type="button" className="btn btn-danger" onClick={() => handleRemoveCombination(index)}>
                                                    <i className="mdi mdi-trash-can"></i> Remove
                                                  </button> */}
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>

                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  </div>
                                  <div className="p-1 flex-shrink-1 bd-highlight">
                                    <button type="button" className="btn btn-danger py-2" onClick={() => handleRemoveCombination(index)}>
                                      <i className="mdi mdi-trash-can"></i>
                                    </button>
                                  </div>


                                </div>
                              )
                            )}

                          </Accordion>
                        </div>

                        {/* {productData.combinations.map(
                          (combination: any, index: any) => (
                            <div key={index}>
                              <hr />

                              <Row>
                                <Col lg={6}>
                                  <div className="">
                                    <div className="d-flex justify-content-around align-items-center">
                                      <p>
                                        Color :{" "}
                                        {combination.attributes.Color || "-"}
                                      </p>
                                      <p>
                                        Material :{" "}
                                        {combination.attributes.Material || "-"}
                                      </p>
                                      <p>
                                        Shape :{" "}
                                        {combination.attributes.Shape || "-"}
                                      </p>
                                      <p>
                                        Size :{" "}
                                        {combination.attributes.Size || "-"}
                                      </p>
                                    </div>
                                    <Row className="mb-3">
                                      <Col>
                                        <div className="">
                                          <p>Product Code</p>
                                          <h4 className="badge bg-warning text-white p-1">
                                            AUTO GEN
                                          </h4>
                                        </div>
                                      </Col>

                                      <Col>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="formGridVariant"
                                        >
                                          <Form.Label> Variant </Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter variant"
                                            value={combination.product_variant}
                                            onChange={(e: any) =>
                                              setProductData(
                                                (prevData: any) => {
                                                  const updatedCombinations = [
                                                    ...prevData.combinations,
                                                  ];
                                                  updatedCombinations[
                                                    index
                                                  ].product_variant =
                                                    e.target.value;
                                                  return {
                                                    ...prevData,
                                                    combinations:
                                                      updatedCombinations,
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                    <Row className="mb-3">
                                      <Col>
                                        <Form.Group
                                          as={Col}
                                          controlId="formGridPrice"
                                        >
                                          <Form.Label>Unit Price</Form.Label>
                                          <Form.Control
                                            type="number"
                                            placeholder="₹ 99"
                                            value={combination.unit_price}
                                            onChange={(e) =>
                                              setProductData(
                                                (prevData: any) => {
                                                  const updatedCombinations = [
                                                    ...prevData.combinations,
                                                  ];
                                                  let unit_price = parseFloat(
                                                    e.target.value
                                                  );
                                                  updatedCombinations[
                                                    index
                                                  ].unit_price = isNaN(
                                                    unit_price
                                                  )
                                                      ? 0
                                                      : unit_price;
                                                  return {
                                                    ...prevData,
                                                    combinations:
                                                      updatedCombinations,
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </Col>
                                      <Col>
                                        <Form.Group
                                          as={Col}
                                          controlId="formGridPrice"
                                        >
                                          <Form.Label>Cost Price</Form.Label>
                                          <Form.Control
                                            type="number"
                                            placeholder="₹ 99"
                                            value={combination.cost_price}
                                            onChange={(e) =>
                                              setProductData(
                                                (prevData: any) => {
                                                  const updatedCombinations = [
                                                    ...prevData.combinations,
                                                  ];
                                                  let cost_price = parseFloat(
                                                    e.target.value
                                                  );
                                                  updatedCombinations[
                                                    index
                                                  ].cost_price = isNaN(
                                                    cost_price
                                                  )
                                                      ? 0
                                                      : cost_price;
                                                  return {
                                                    ...prevData,
                                                    combinations:
                                                      updatedCombinations,
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </Col>
                                      <Col>
                                        <Form.Group
                                          as={Col}
                                          controlId="formGridPrice"
                                        >
                                          <Form.Label>RRR Price</Form.Label>
                                          <Form.Control
                                            type="number"
                                            placeholder="₹ 99"
                                            value={combination.rrr_price}
                                            onChange={(e) =>
                                              setProductData(
                                                (prevData: any) => {
                                                  const updatedCombinations = [
                                                    ...prevData.combinations,
                                                  ];
                                                  let rrr_price = parseFloat(
                                                    e.target.value
                                                  );
                                                  updatedCombinations[
                                                    index
                                                  ].rrr_price = isNaN(rrr_price)
                                                      ? 0
                                                      : rrr_price;
                                                  return {
                                                    ...prevData,
                                                    combinations:
                                                      updatedCombinations,
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </Col>
                                    </Row>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formGridAddress1"
                                    >
                                      <Form.Label>Description</Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        placeholder="Enter description"
                                        value={combination.description}
                                        onChange={(e: any) =>
                                          setProductData((prevData: any) => {
                                            const updatedCombinations = [
                                              ...prevData.combinations,
                                            ];
                                            updatedCombinations[
                                              index
                                            ].description = e.target.value;
                                            return {
                                              ...prevData,
                                              combinations: updatedCombinations,
                                            };
                                          })
                                        }
                                      />
                                    </Form.Group>

                                    <Row className="mb-3">
                                      <Form.Group
                                        as={Col}
                                        controlId={`category_${index}`}
                                      >
                                        <Form.Label>Category</Form.Label>
                                        <Form.Select
                                          defaultValue="Choose..."
                                          onChange={(e: any) =>
                                            handleCategoryChange(
                                              index,
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option disabled>Choose...</option>
                                          {categoryList.map((category: any) => (
                                            <option
                                              key={category.product_category_id}
                                              value={
                                                category.product_category_id
                                              }
                                            >
                                              {category.category_name}
                                            </option>
                                          ))}
                                        </Form.Select>
                                      </Form.Group>

                                      <Form.Group
                                        as={Col}
                                        controlId={`subcategory_${index}`}
                                      >
                                        <Form.Label>Sub Category</Form.Label>
                                        <Form.Select
                                          defaultValue="Choose..."
                                          onChange={(e: any) =>
                                            handleSubCategoryChange(
                                              index,
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option disabled>Choose...</option>
                                          {productData.combinations[index]
                                            ?.product_category_id &&
                                            categoryList
                                              .find(
                                                (cat: any) =>
                                                  cat.product_category_id ===
                                                  productData.combinations[
                                                    index
                                                  ].product_category_id
                                              )
                                              ?.wm_product_subcategories.map(
                                                (subcategory: any) => (
                                                  <option
                                                    key={
                                                      subcategory.product_subcategory_id
                                                    }
                                                    value={
                                                      subcategory.product_subcategory_id
                                                    }
                                                  >
                                                    {
                                                      subcategory.subcategory_name
                                                    }
                                                  </option>
                                                )
                                              )}
                                        </Form.Select>
                                      </Form.Group>
                                    </Row>

                                    <Row>
                                      <Col>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="formGridStock"
                                        >
                                          <Form.Label>Stock</Form.Label>
                                          <Form.Control
                                            type="number"
                                            min="1"
                                            name="stock"
                                            placeholder="stock Qty"
                                            style={{ width: "120px" }}
                                            value={
                                              combination.stock
                                                ? combination.stock
                                                : 1
                                            }
                                            onChange={(e: any) => {
                                              setProductData(
                                                (prevData: any) => {
                                                  const updatedCombinations = [
                                                    ...prevData.combinations,
                                                  ];
                                                  updatedCombinations[
                                                    index
                                                  ].stock = e.target.value;
                                                  return {
                                                    ...prevData,
                                                    combinations:
                                                      updatedCombinations,
                                                  };
                                                }
                                              );
                                            }}
                                          />
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <Card>
                                    <Card.Body>
                                      <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                                        Product Images
                                      </h5>
                                      <FileUploader
                                        setProductImages={setProductImages}
                                        setProductData={setProductData}
                                        index={index}
                                      />
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          )
                        )} */}
                      </div>
                      <div className="text-center">
                        <button type="button" className="btn btn-primary"
                          disabled={productData.combinations.length === color.length && color.length > 0 ? color.some((i: any) => !i.color) : true}
                          onClick={handleSubmit}>
                          Submit
                        </button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </div >
        )
        }
      </form >
    </div >
  );
};

export default AddProductForm;
