import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// components
// import { VerticalForm, FormInput } from "../../../../components/";
// import { AddNewCustomer } from "../../../../redux/actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { AddNewCustomer, UpdateCustomer } from "../../../redux/actions";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import notify from "../../../components/Notification/notify";
// import { AppDispatch } from "../../../../redux/store";

interface AddCustomerProps {
  show: boolean;
  userData: any;
  onHide: () => void;
  // onSubmit: (value: any) => void;
}

const AddClientCustomer = ({ show, onHide, userData }: AddCustomerProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword(!confirmShowPassword);
  };

  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedState, setSelectedState] = useState<any>(null);
  const [selectedCity, setSelectedCity] = useState<any>(null);
  // console.log('userData :>> ', userData);
  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      const country: any = userData.country ? Country.getAllCountries().find(c => c.name === userData.country) : "";
      setSelectedCountry(country);
      // console.log('country :>> ', country);

      const state: any = country ? State.getStatesOfCountry(country?.isoCode).find(s => s.name === userData.state) : "";
      setSelectedState(state);
      // console.log('state :>> ', state);

      const city = state ? City.getCitiesOfState(Object.keys(country).length > 0 && country?.isoCode, state?.isoCode).find(c => c.name === userData.city) : "";
      setSelectedCity(city);
    }
  }, [userData]);

  const validationSchema = Yup.object().shape({
    // companyName: Yup.string().required("Company name is required"),
    // address: Yup.string().required("Address is required"),
    // mainLine: Yup.string().required("Main Line is required"),
    // townCity: Yup.string().required("Town/city is required"),
    // stateProvince: Yup.string().required("State/province is required"),
    // postcode: Yup.string().required("Postcode is required"),
    // country: Yup.string().required("Country is required"),
    contactName: Yup.string().required("Contact Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required")
    // phoneNumber: Yup.string()
    //   .matches(/^[0-9]+$/, "Invalid phone number")
    //   .required("Phone Number is required"),
    // landLineNumber: Yup.string().matches(/^[0-9]+$/, "Invalid landline number"),
    // gstNumber: Yup.string()
    //   .matches(/^[0-9A-Za-z]+$/, "Invalid GST number")
    //   .required("GST Number is required")
  });

  const initialValues = {
    companyName: userData?.company_name ?? "",
    address: userData?.address_main_line ?? "",
    townCity: userData?.city ?? "",
    stateProvince: userData?.state ?? "",
    postcode: userData?.postcode ?? "",
    country: userData?.country ?? "",
    contactName: userData?.name ?? "",
    email: userData?.email ?? "",
    password: userData?.password ?? "",
    confirm_password: userData?.password ?? "",
    phoneNumber: userData?.phone_number ?? "",
    landLineNumber: userData?.landline_number ?? "",
    gstNumber: userData?.gst_number ?? ""
  };

  const handleSubmit = (values: any) => {
    try {
      if (values.password !== values.confirm_password) {
        notify("Password must be same.", "error")
      } else {
        if (Object.keys(userData).length > 0) {
          let reqBody = {
            user_id: userData?.user_id,
            name: values.contactName,
            email: values.email,
            password: values.password,
            company_name: values.companyName || "",
            address_main_line: values.address || "",
            city: values.townCity || "",
            state: values.stateProvince || "",
            postcode: values.postcode || "",
            country: values.country || "",
            phone_number: values.phoneNumber || "",
            landline_number: values.landLineNumber || "",
            gst_number: values.gstNumber || "",
            is_crm_client: 1
          };
          dispatch(UpdateCustomer(reqBody));
          onHide();
        } else {
          let reqBody = {
            name: values.contactName || "",
            email: values.email || "",
            password: values.password || "",
            company_name: values.companyName || "",
            address_main_line: values.address || "",
            city: values.townCity || "",
            state: values.stateProvince || "",
            postcode: values.postcode || "",
            country: values.country || "",
            phone_number: values.phoneNumber || "",
            landline_number: values.landLineNumber || "",
            gst_number: values.gstNumber || "",
            is_crm_client: 1
          };
          dispatch(AddNewCustomer(reqBody));
          onHide();
        }
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header className="bg-light" onHide={onHide} closeButton>
          <Modal.Title className="m-0">{userData && Object.keys(userData).length > 0 ? "Update" : "Add"} New Customers</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, setFieldValue }) => (
              // <Form>
              //   <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
              //     Personal Information
              //   </h5>
              //   <div className="mb-3">
              //     <label className="form-label">Company Name:</label>
              //     <Field
              //       className={`form-control ${errors.companyName && touched.companyName && "is-invalid"
              //         }`}
              //       type="text"
              //       name="companyName"
              //       placeholder="Enter company name (e.g., ABC Corporation)"
              //     />
              //     <ErrorMessage
              //       name="companyName"
              //       component="div"
              //       className="invalid-feedback"
              //     />
              //   </div>

              //   <div className="mb-3">
              //     <label className="form-label">Address:</label>
              //     <Field
              //       as={"textarea"}
              //       className={`form-control ${errors.address && touched.address && "is-invalid"
              //         }`}
              //       name="address"
              //       placeholder="Enter address (e.g., 123 Main St, Apartment 4B)"
              //     />
              //     <ErrorMessage
              //       name="address"
              //       component="div"
              //       className="invalid-feedback"
              //     />
              //   </div>

              //   <Row>
              //     <Col>
              //       <div className="mb-3">
              //         <label className="form-label">Town/City:</label>
              //         <Field
              //           className={`form-control ${errors.townCity && touched.townCity && "is-invalid"
              //             }`}
              //           type="text"
              //           name="townCity"
              //           placeholder="Enter town/city (e.g., New York)"
              //         />
              //         <ErrorMessage
              //           name="townCity"
              //           component="div"
              //           className="invalid-feedback"
              //         />
              //       </div>
              //     </Col>
              //     <Col>
              //       <div className="mb-3">
              //         <label className="form-label">Postcode:</label>
              //         <Field
              //           className={`form-control ${errors.postcode && touched.postcode && "is-invalid"
              //             }`}
              //           type="text"
              //           name="postcode"
              //           placeholder="Enter postcode (e.g., 10001)"
              //         />
              //         <ErrorMessage
              //           name="postcode"
              //           component="div"
              //           className="invalid-feedback"
              //         />
              //       </div>
              //     </Col>
              //   </Row>

              //   <Row>
              //     <Col>
              //       <div className="mb-3">
              //         <label className="form-label">State/Province:</label>
              //         <Field
              //           className={`form-control ${errors.stateProvince &&
              //             touched.stateProvince &&
              //             "is-invalid"
              //             }`}
              //           type="text"
              //           name="stateProvince"
              //           placeholder="Enter state/province (e.g., California)"
              //         />
              //         <ErrorMessage
              //           name="stateProvince"
              //           component="div"
              //           className="invalid-feedback"
              //         />
              //       </div>
              //     </Col>
              //     <Col>
              //       <div className="mb-3">
              //         <label className="form-label">Country:</label>
              //         <Field
              //           className={`form-control ${errors.country && touched.country && "is-invalid"
              //             }`}
              //           type="text"
              //           name="country"
              //           placeholder="Enter country (e.g., United States)"
              //         />
              //         <ErrorMessage
              //           name="country"
              //           component="div"
              //           className="invalid-feedback"
              //         />
              //       </div>
              //     </Col>
              //   </Row>

              //   <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
              //     Company info
              //   </h5>

              //   <div className="mb-3">
              //     <label className="form-label">Contact Name:</label>
              //     <Field
              //       className={`form-control ${errors.contactName && touched.contactName && "is-invalid"
              //         }`}
              //       type="text"
              //       name="contactName"
              //       placeholder="Enter contact name (e.g., John Doe)"
              //     />
              //     <ErrorMessage
              //       name="contactName"
              //       component="div"
              //       className="invalid-feedback"
              //     />
              //   </div>
              //   <div className="mb-3">
              //     <label className="form-label">Email:</label>
              //     <Field
              //       className={`form-control ${errors.email && touched.email && "is-invalid"
              //         }`}
              //       type="email"
              //       name="email"
              //       placeholder="Enter email (e.g., john@example.com)"
              //     />
              //     <ErrorMessage
              //       name="email"
              //       component="div"
              //       className="invalid-feedback"
              //     />
              //   </div>

              //   <div className="mb-3">
              //     <label htmlFor="password" className="form-label">
              //       Password
              //     </label>
              //     <div className="input-group">
              //       <Field
              //         type={showPassword ? "text" : "password"}
              //         id="password"
              //         name="password"
              //         placeholder="Enter password"
              //         className={`form-control ${errors.password && touched.password ? "is-invalid" : ""
              //           }`}
              //       />
              //       <button
              //         type="button"
              //         className="btn btn-outline-secondary"
              //         onClick={togglePasswordVisibility}
              //       >
              //         {showPassword ? (
              //           <span className="mdi mdi-eye-off"></span>
              //         ) : (
              //           <span className="mdi mdi-eye"></span>
              //         )}
              //       </button>
              //     </div>
              //     <ErrorMessage
              //       name="password"
              //       component="div"
              //       className="invalid-feedback"
              //     />
              //   </div>

              //   {/* <div className="mb-3">
              //     <label className="form-label">Password:</label>
              //     <Field
              //       className={`form-control ${
              //         errors.password && touched.password && "is-invalid"
              //       }`}
              //       type="password"
              //       name="password"
              //       placeholder="Enter password"
              //     />
              //     <ErrorMessage
              //       name="password"
              //       component="div"
              //       className="invalid-feedback"
              //     />
              //   </div> */}
              //   <div className="mb-3">
              //     <label className="form-label">Phone Number:</label>
              //     <Field
              //       className={`form-control ${errors.phoneNumber && touched.phoneNumber && "is-invalid"
              //         }`}
              //       type="text"
              //       name="phoneNumber"
              //       placeholder="Enter phone number (e.g., +1234567890)"
              //     />
              //     <ErrorMessage
              //       name="phoneNumber"
              //       component="div"
              //       className="invalid-feedback"
              //     />
              //   </div>

              //   <Row>
              //     <Col>
              //       <div className="mb-3">
              //         <label className="form-label">Land Line Number:</label>
              //         <Field
              //           className={`form-control ${errors.landLineNumber &&
              //             touched.landLineNumber &&
              //             "is-invalid"
              //             }`}
              //           type="text"
              //           name="landLineNumber"
              //           placeholder="Enter landline number"
              //         />
              //         <ErrorMessage
              //           name="landLineNumber"
              //           component="div"
              //           className="invalid-feedback"
              //         />
              //       </div>
              //     </Col>
              //     <Col>
              //       <div className="mb-3">
              //         <label className="form-label">GST Number:</label>
              //         <Field
              //           className={`form-control ${errors.gstNumber && touched.gstNumber && "is-invalid"
              //             }`}
              //           type="text"
              //           name="gstNumber"
              //           placeholder="Enter GST number"
              //         />
              //         <ErrorMessage
              //           name="gstNumber"
              //           component="div"
              //           className="invalid-feedback"
              //         />
              //       </div>
              //     </Col>
              //   </Row>
              //   <div className="text-end">
              //     <button type="submit" className="btn btn-primary">
              //       {Object.keys(userData).length > 0 ? "Update" : "Submit"}
              //     </button>
              //   </div>
              // </Form>

              <Form>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  Company Information
                </h5>

                <Row>
                  <Col>
                    <div className="mb-3">
                      <label className="form-label">Contact Name:</label>
                      <Field
                        className={`form-control ${errors.contactName && touched.contactName && "is-invalid"
                          }`}
                        type="text"
                        name="contactName"
                        placeholder="Enter contact name (e.g., John Doe)"
                      />
                      <ErrorMessage
                        name="contactName"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <label className="form-label">Company Name:</label>
                      <Field
                        className={`form-control ${errors.companyName && touched.companyName && "is-invalid"
                          }`}
                        type="text"
                        name="companyName"
                        placeholder="Enter company name (e.g., ABC Corporation)"
                      />
                      <ErrorMessage
                        name="companyName"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="mb-3">
                      <label className="form-label">Land Line Number:</label>
                      <Field
                        className={`form-control ${errors.landLineNumber &&
                          touched.landLineNumber &&
                          "is-invalid"
                          }`}
                        type="text"
                        name="landLineNumber"
                        placeholder="Enter landline number"
                      />
                      <ErrorMessage
                        name="landLineNumber"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <label className="form-label">GST Number:</label>
                      <Field
                        className={`form-control ${errors.gstNumber && touched.gstNumber && "is-invalid"
                          }`}
                        type="text"
                        name="gstNumber"
                        placeholder="Enter GST number"
                      />
                      <ErrorMessage
                        name="gstNumber"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
                <div className="mb-3">
                  <label className="form-label">Address:</label>
                  <Field
                    as={"textarea"}
                    className={`form-control ${errors.address && touched.address && "is-invalid"
                      }`}
                    name="address"
                    placeholder="Enter address (e.g., 123 Main St, Apartment 4B)"
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <Row>
                  <Col>
                    <div className="mb-3">
                      <label className="form-label">Country:</label>
                      <Select
                        options={Country.getAllCountries()}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.isoCode}
                        value={selectedCountry}
                        onChange={(item) => {
                          setSelectedCountry(item);
                          setSelectedState(null);
                          setSelectedCity(null);
                          setFieldValue("country", item.name);
                          setFieldValue("stateProvince", "");
                          setFieldValue("townCity", "");
                        }}
                      />
                      {/* <Field
                      className={`form-control ${errors.townCity && touched.townCity && "is-invalid"
                        }`}
                      type="text"
                      name="townCity"
                      placeholder="Enter town/city (e.g., New York)"
                    /> */}
                      <ErrorMessage
                        name="townCity"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="mb-3">
                      <label className="form-label">State/Province:</label>
                      <Select
                        options={State.getStatesOfCountry(selectedCountry?.isoCode || "")}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.isoCode}
                        value={selectedState}
                        onChange={(item) => {
                          setSelectedState(item);
                          setSelectedCity(null);
                          setFieldValue("stateProvince", item.name);
                          setFieldValue("townCity", "");
                        }}
                        isDisabled={!selectedCountry}
                      />
                      {/* <Field
                      className={`form-control ${errors.stateProvince &&
                        touched.stateProvince &&
                        "is-invalid"
                        }`}
                      type="text"
                      name="stateProvince"
                      placeholder="Enter state/province (e.g., California)"
                    /> */}
                      <ErrorMessage
                        name="stateProvince"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="mb-3">
                      <label className="form-label">City:</label>
                      <Select
                        options={City.getCitiesOfState(selectedCountry?.isoCode || "", selectedState?.isoCode || "")}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        value={selectedCity}
                        onChange={(item) => {
                          setSelectedCity(item);
                          setFieldValue("townCity", item.name);
                        }}
                        isDisabled={!selectedState}
                      />
                      {/* <Field
                      className={`form-control ${errors.country && touched.country && "is-invalid"
                        }`}
                      type="text"
                      name="country"
                      placeholder="Enter country (e.g., United States)"
                    /> */}
                      <ErrorMessage
                        name="country"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="mb-3">
                      <label className="form-label">Postcode:</label>
                      <Field
                        className={`form-control ${errors.postcode && touched.postcode && "is-invalid"
                          }`}
                        type="text"
                        name="postcode"
                        placeholder="Enter postcode (e.g., 10001)"
                      />
                      <ErrorMessage
                        name="postcode"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>

                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  Account Creation Information
                </h5>

                <Row>
                  <Col>
                    <div className="mb-3">
                      <label className="form-label">Phone Number:</label>
                      <Field
                        className={`form-control ${errors.phoneNumber && touched.phoneNumber && "is-invalid"
                          }`}
                        type="text"
                        name="phoneNumber"
                        placeholder="Enter phone number (e.g., +1234567890)"
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <label className="form-label">Email:</label>
                      <Field
                        className={`form-control ${errors.email && touched.email && "is-invalid"
                          }`}
                        type="email"
                        name="email"
                        placeholder="Enter email (e.g., john@example.com)"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="mb-3">
                      <label className="form-label">Password:</label>
                      <div className="input-group">
                        <Field
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          placeholder="Enter password"
                          className={`form-control ${errors.password && touched.password && "is-invalid"
                            }`}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <span className="mdi mdi-eye-off"></span>
                          ) : (
                            <span className="mdi mdi-eye"></span>
                          )}
                        </button>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                  <Col>
                    {/* {Object.keys(userData).length > 0 ? (<></>) : (
                      <> */}
                    <div className="mb-3">
                      <label className="form-label">Confirm Password:</label>
                      <div className="input-group">
                        <Field
                          type={confirmShowPassword ? "text" : "password"}
                          id="confirm_password"
                          name="confirm_password"
                          placeholder="Enter confirm password"
                          className={`form-control ${errors.confirm_password && touched.confirm_password && "is-invalid"}`}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          {confirmShowPassword ? <span className="mdi mdi-eye-off"></span> : <span className="mdi mdi-eye"></span>}
                        </button>
                      </div>
                      <ErrorMessage
                        name="confirm_password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    {/* </>
                    )} */}
                  </Col>
                </Row>

                <div className="text-end">
                  <button type="submit" className="btn btn-primary">
                    {Object.keys(userData).length > 0 ? "Update" : "Submit"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddClientCustomer;
