import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage, FieldProps } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  AddSubAdminAction,
  addTaskAction,
  editTaskAction,
  fetchSubAdminListAction,
} from "../../../redux/actions";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface FormValues {
  title: string;
  task_description: string;
  user_id: string;
  customer: { value: string; label: string; image: string }[]; // Updated type for multi-select
  dueDate: any;
}

interface AddClientTaskProps {
  show: boolean;
  task: any;
  onHide: () => void;
  onSubmit: (value: any) => void;
  customerList: any;
}

const AddClientTask = ({
  show,
  task,
  onHide,
  onSubmit,
  customerList,
}: AddClientTaskProps) => {
  const [subAdminList, setSubAdminList] = useState<any>([]);
  const [clientList, setClientList] = useState<any>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const imageUrl = "https://i.pravatar.cc/300";

  const { subAdminDetails, loading, error } = useSelector(
    (state: RootState) => ({
      subAdminDetails: state.TaskReducer.subAdminDetails,
      loading: state.TaskReducer.loading,
      error: state.TaskReducer.error,
    })
  );

  const customerData = customerList.map((user: any) => ({
    value: user.user_id,
    label: (
      <div>
        <img
          src={
            user?.profile_image || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNUKbV4ZCrVOwTMkfnN10Mfhwp7BSiVb64BzDuE_lA9w&s"
          }
          alt={user.name}
          style={{ width: 24, height: 24, marginRight: 8, borderRadius: "50%" }}
        />
        {/* <img src={user.profile_image.data ? user.profile_image.data : 'https://i.pravatar.cc/300' } alt={user.name} style={{ width: 24, height: 24, marginRight: 8 }} /> */}
        {user.name.charAt(0).toUpperCase() + user.name.slice(1)}
      </div>
    )
    // label: user.name.charAt(0).toUpperCase() + user.name.slice(1),
    // image: user.profile_image.data
  }));

  const optionsWithSelectAll = [
    {
      value: 'selectAll',
      label: isAllSelected ? 'DeSelect All' : 'Select All',
      isSelectAll: true,
    },
    ...customerData,
  ];

  useEffect(() => {
    if (
      subAdminDetails?.statusCode === 200 &&
      subAdminDetails?.data !== undefined &&
      subAdminDetails?.data !== null
    ) {
      setSubAdminList(subAdminDetails?.data);
    }
  }, [subAdminDetails]);

  const initialValues: FormValues = {
    customer: Object.keys(task).length > 0 ? clientList : [],
    title: task?.title || "",
    task_description: task?.task_description || "",
    user_id: task?.user_id || "",
    dueDate: task?.due_date ? new Date(task?.due_date * 1000) : null,
  };
  useEffect(() => {
    if (Object.keys(task).length > 0) {
      let _clientArray = task?.taskCustomerData.map((i: any) => i.taskCustomerUserData.user_id)
      let temp = customerData.filter((ele: any) => {
        if (_clientArray.includes(ele.value)) {
          return ele;
        }
      });
      setClientList(temp);
      initialValues.customer = temp;
    }
  }, [task]);

  useEffect(() => {
    dispatch(fetchSubAdminListAction());
  }, [dispatch]);

  const handleSubmit = (values: FormValues, { resetForm }: { resetForm: () => void }) => {
    let _customerList = values.customer.map((i: any) => i.value);
    const reqBody: any = {
      clientArr: _customerList,
      title: values.title,
      task_description: values.task_description,
      user_id: values.user_id,
      due_date: values.dueDate ? Math.floor(values.dueDate.getTime() / 1000) : null,
      type: 1,
    };
    if (Object.keys(task).length > 0) {
      //Update Call
      reqBody.task_id = task?.task_id;
      dispatch(editTaskAction(reqBody));
    } else {
      //Add Call
      dispatch(addTaskAction(reqBody));
      resetForm();
    }
  };

  const validationSchema = Yup.object().shape({
    // customer: Yup.array()
    //   .of(Yup.object().shape({
    //     value: Yup.string().required("Customer value is required"),
    //     label: Yup.string().required("Customer label is required"),
    //   }))
    //   .min(1, "At least one customer must be selected")
    //   .required("Customer selection is required"),

    title: Yup.string()
      // .min(3, "Title must be at least 3 characters long")
      // .max(50, "Title must not exceed 50 characters")
      .required("Title is required"),

    task_description: Yup.string()
      // .min(5, "Description must be at least 5 characters long")
      // .max(200, "Description must not exceed 200 characters")
      .required("Task description is required"),

    // user_id: Yup.string()
    //   .required("An admin must be assigned"),

    dueDate: Yup.date()
      .nullable()
      .required("Due date is required")
      .min(new Date(), "Due date cannot be in the past"),
  });

  return (
    <Modal
      show={show}
      size="lg"
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-light" onHide={onHide} closeButton>
        <Modal.Title className="m-0">
          {task && Object.keys(task).length > 0 ? "Update" : "Add"} Task Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form>
              <div className="mb-3">
                <label htmlFor="customer">Customer Name</label>
                <Select
                  isMulti
                  options={optionsWithSelectAll}
                  className="react-select react-select-container"
                  classNamePrefix="react-select"
                  name="customer"
                  value={clientList}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option: any) => option.value
                    );
                    if (selectedValues.includes("selectAll")) {
                      if (isAllSelected) {
                        // Deselect "Select All"
                        setClientList([]);
                        setFieldValue("customer", []);
                        setIsAllSelected(false);
                      } else {
                        // Select all
                        const allCustomerIds = customerData.map(
                          (option: any) => option
                        );
                        setClientList(allCustomerIds);
                        setFieldValue(
                          "customer",
                          allCustomerIds.map((option: any) => option)
                        );
                        setIsAllSelected(true);
                      }
                    } else {
                      // Handle individual selections
                      const selectedCustomerIds = selectedOptions.map(
                        (option) => option
                      );
                      setClientList(selectedOptions);
                      setFieldValue("customer", selectedCustomerIds);
                      setIsAllSelected(
                        selectedCustomerIds.length === customerData.length
                      );
                    }
                  }}
                  closeMenuOnSelect={false}
                />
                {/* <Select
                  isMulti={true}
                  options={customerData}
                  className="react-select react-select-container"
                  classNamePrefix="react-select"
                  name="customer"
                  value={clientList}
                  onChange={(selectedOption) => {
                    setClientList(selectedOption);
                    const selectedCustomerIds = selectedOption.map(
                      (option) => option
                    );
                    setFieldValue("customer", selectedCustomerIds);
                  }}
                /> */}

                <ErrorMessage
                  name="customer"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="title">Title</label>
                <Field
                  placeholder="Enter title"
                  name="title"
                  id="title"
                  type="text"
                  className={`form-control mb-1 ${errors.title && touched.title ? "is-invalid" : ""
                    }`}
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="description">Task Description</label>
                <Field
                  placeholder="Enter description"
                  name="task_description"
                  id="task_description"
                  as="textarea"
                  className={`form-control mb-1 ${errors.task_description && touched.task_description
                    ? "is-invalid"
                    : ""
                    }`}
                />
                <ErrorMessage
                  name="task_description"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="user_id">Assign To</label>
                <Field
                  as="select"
                  className="form-select"
                  id="user_id"
                  name="user_id"
                >
                  <option value="" disabled>
                    Select an admin
                  </option>

                  {subAdminList.length > 0 &&
                    subAdminList.map((item: any) => (
                      <option key={item.user_id} value={item.user_id}>
                        {item.name}
                      </option>
                    ))}
                  {/* <option value="user2">User 2</option>
                <option value="user3">User 3</option> */}
                </Field>
                <ErrorMessage name="user_id" component="div" className="text-danger" />
              </div>

              <div className="mb-3">
                <label htmlFor="dueDate">Due Date</label>
                <Field name="dueDate">
                  {({ field }: FieldProps<Date | null>) => (
                    <DatePicker
                      selected={field.value}
                      onChange={(date: Date | null) => setFieldValue('dueDate', date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/MM/yyyy"
                      className="form-control"
                      id="dueDate"
                      name="dueDate"
                    />
                  )}
                </Field>
                <ErrorMessage name="dueDate" component="div" className="text-danger" />
                {/* <ErrorMessage name="user_id" component="div" /> */}
              </div>

              {/* Other fields */}
              <div className="text-end mt-3">
                <button className="btn btn-success" type="submit">
                  {task && Object.keys(task).length > 0 ? "Update" : "Create"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
export default AddClientTask;
