import { DashboardActionTypes } from "./constants";

export interface DashboardAction {
  type:
  | DashboardActionTypes.API_RESPONSE_SUCCESS
  | DashboardActionTypes.API_RESPONSE_ERROR
  | DashboardActionTypes.FETCH_USERS_STATS;
  payload: {} | any;
}

interface TaskData {
  taskDetails: [];
  loading: false;
  error: null;
}

// common success
export const dashboardApiResponseSuccess = (
  actionType: string,
  data: TaskData | {}
): DashboardAction => ({
  type: DashboardActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const dashboardApiResponseError = (
  actionType: string,
  error: string
): DashboardAction => ({
  type: DashboardActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//fetch user stats | dashboard
export const fetchUserStatsAction = () => ({
  type: DashboardActionTypes.FETCH_USERS_STATS,
});
