import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import ProductReducer from "./product/reducers";
import SubAdminReducer from "./subAdmin/reducers";
import CategoryReducer from "./category/reducers";
import TaskReducer from "./tasks/reducers";
import PermissionReducer from "./permissions/reducers";
import CustomerReducer from "./customers/reducers";
import OrdersReducer from "./orders/reducers";
import AdminProfileReducer from "./adminProfile/reducers";
import UserProfileReducer from "./userProfile/reducers";
import UserProductReducer from "./UserRedux/UserProduct/reducers";
import UserOrderReducer from "./UserRedux/UserOrder/reducers";
import UserCartReducer from "./UserRedux/UserCart/reducers";
import DashboardReducer from "./dashboard/reducers";

export default combineReducers({
  Auth,
  Layout,
  ProductReducer,
  CategoryReducer,
  SubAdminReducer,
  TaskReducer,
  PermissionReducer,
  CustomerReducer,
  OrdersReducer,
  AdminProfileReducer,
  UserProfileReducer,
  UserProductReducer,
  UserOrderReducer,
  UserCartReducer,
  DashboardReducer,
});
