import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Spinner } from "react-bootstrap";
import Dropzone from "react-dropzone";
import axios from "axios";

interface FileType extends File {
  preview?: string;
  formattedSize?: string;
  path?: string;
}

interface FileUploaderProps {
  onFileUpload?: (files: FileType[]) => void;
  showPreview?: boolean;
  setProductImages?: React.Dispatch<React.SetStateAction<FileType[]>>;
  setProductData?: React.Dispatch<React.SetStateAction<any>>; // Update with more specific type if possible
  index?: number;
  product_name?: {
    name?: string;
    wm_product_photos?: { photo_url: string }[];
  };
  is_edit?: boolean;
  setEditProductImages?: React.Dispatch<React.SetStateAction<string[]>>;
}

const FileUploader: React.FC<FileUploaderProps> = (props) => {
  const [selectedFiles, setSelectedFiles] = useState<FileType[]>([]);
  const [loading, setLoading] = useState<boolean>(false); // Loading state

  useEffect(() => {
    if (props.product_name && props.is_edit) {
      props.product_name.wm_product_photos &&
        props.product_name.wm_product_photos.forEach((i) => {
          setSelectedFiles((prevFiles: any) => [
            ...prevFiles,
            { preview: i.photo_url },
          ]);
          props.setEditProductImages &&
            props.setEditProductImages((prevFiles) => [
              ...prevFiles,
              i.photo_url,
            ]);
        });
    }
  }, [props.is_edit, props.product_name, props.setEditProductImages]);

  const uploadProductImg = async (idx: any, name: any, img: any) => {
    try {
      const formData = new FormData();
      formData.append("product_name", name);
      formData.append("productIndex", idx);
      formData.append("image", img);

      let response: any = await axios.post(process.env.REACT_APP_API_URL + "/admin/uploadeProductImage", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
      );
      return response;
    } catch (error) {
      console.error("Error uploading image", error);
    }
  };

  const handleAcceptedFiles = async (files: FileType[]) => {
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"]; // Add more image types if needed
    const validImageFiles: FileType[] = [];
    const invalidFiles: FileType[] = [];

    files.forEach((file) => {
      if (file.size > 2 * 1024 * 1024) {
        // Check if file size exceeds 2MB
        alert(`File ${file.name} exceeds the maximum size of 2MB.`);
        invalidFiles.push(file);
      } else if (!allowedTypes.includes(file.type)) {
        // Check if file type is not allowed
        alert(`File ${file.name} is not an allowed image type.`);
        invalidFiles.push(file);
      } else {
        validImageFiles.push(file);
      }
    });

    if (validImageFiles.length > 0) {
      setLoading(true); // Set loading to true before uploading

      const updatedFiles = props.showPreview
        ? validImageFiles.map((file) => ({
          ...file,
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        }))
        : [];

      if (props.is_edit) {
        for (let img of validImageFiles) {
          let idx = (props.index || 0) + 1;
          let name = props.product_name?.name || "";
          let uploadNewImg = await uploadProductImg(idx, name, img);
          if (uploadNewImg) {
            let publicUrl = uploadNewImg?.data?.data?.publicUrl;
            setSelectedFiles((prevFiles: any) => [
              ...prevFiles,
              { preview: publicUrl },
            ]);
            props.setEditProductImages &&
              props.setEditProductImages((prevFiles) => [
                ...prevFiles,
                publicUrl,
              ]);
          } else {
            alert("Something went wrong while uploading the image.");
          }
        }
        props.onFileUpload && props.onFileUpload(validImageFiles);
      } else {
        let idx = (props.index || 0) + 1;
        let name = props.product_name?.name || "";
        let imageUploadPromises = validImageFiles.map((img) =>
          uploadProductImg(idx, name, img)
        );
        let uploadResponses = await Promise.all(imageUploadPromises);
        uploadResponses.forEach((temp, index) => {
          if (temp) {
            let publicUrl = temp?.data?.data?.publicUrl;
            setSelectedFiles((prevFiles) => [
              ...prevFiles,
              {
                ...validImageFiles[index],
                preview: publicUrl,
              },
            ]);
            props.setProductImages &&
              props.setProductImages((prevImages) => [
                ...prevImages,
                {
                  ...validImageFiles[index],
                  preview: publicUrl,
                },
              ]);
            addItems(props.index || 0, publicUrl);
          } else {
            alert("Something went wrong while uploading the image.");
          }
        });
        props.onFileUpload && props.onFileUpload(validImageFiles);
      }

      setLoading(false); // Set loading to false after uploading
    }

    if (invalidFiles.length > 0) {
      // Display alert for invalid files
      alert("Some files were not added because they are not allowed.");
    }
  };

  const addItems = async (index: number, publicUrl: string) => {
    props.setProductData &&
      props.setProductData((prevData: any) => {
        const updatedCombinations = [...prevData.combinations];
        const currentCombination = updatedCombinations[index];
        if (!currentCombination.photo_url) {
          currentCombination.photo_url = [];
        }
        currentCombination.photo_url.push(publicUrl);
        return {
          ...prevData,
          combinations: updatedCombinations,
        };
      });
  };

  const removeItems = (index: number, img: string[]) => {
    props.setProductData &&
      props.setProductData((prevData: any) => {
        const updatedCombinations = [...prevData.combinations];
        updatedCombinations[index].photo_url = img;
        return {
          ...prevData,
          combinations: updatedCombinations,
        };
      });
  };

  const formatBytes = (bytes: number, decimals: number = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const removeFile = (fileIndex: number) => {
    const newFiles = selectedFiles.filter((_, index) => index !== fileIndex);
    setSelectedFiles(newFiles);
    props.setProductImages && props.setProductImages(newFiles);
    removeItems(props.index || 0, newFiles.map((f) => f.preview || ""));
    props.onFileUpload && props.onFileUpload(newFiles);
  };

  const EditRemoveFile = (fileIndex: number) => {
    const newFiles = selectedFiles.filter((_, index) => index !== fileIndex);
    setSelectedFiles(newFiles);
    props.setEditProductImages &&
      props.setEditProductImages(newFiles.map((f) => f.preview || ""));
    props.onFileUpload && props.onFileUpload(newFiles);
  };

  useEffect(() => {
    // Clean up the URLs when component unmounts or when files change
    return () => {
      selectedFiles.forEach((file) => {
        if (file.preview) {
          URL.revokeObjectURL(file.preview);
        }
      });
    };
  }, [selectedFiles]);

  const renderFileCard = (file: FileType, index: number, isEdit: boolean) => (
    <Card className="mt-1 mb-0 shadow-none border" key={index + "-file"}>
      <div className="p-2">
        <Row className="align-items-center">
          {file.preview ? (
            <Col className="col-auto">
              <img
                data-dz-thumbnail=""
                className="avatar-sm rounded bg-light"
                alt={file.name}
                src={file.preview}
              />
              <Link to="#" className="text-muted fw-bold ms-2">
                {file.path ? file.path.split(".")[0] : "Image"}
              </Link>

            </Col>
          ) : (
            <Col className="col-auto">
              <div className="avatar-sm">
                <span className="avatar-title bg-primary rounded">
                  {file.type.split("/")[0]}
                </span>
              </div>
            </Col>
          )}
          <Col className="ps-0">
            <Link to="#" className="text-muted fw-bold">
              {file.name}
            </Link>
            <p className="mb-0">
              <strong>{file.formattedSize}</strong>
            </p>
          </Col>
          <Col className="col-auto">
            <Link
              to="#"
              className="btn btn-link btn-lg text-muted"
              onClick={() =>
                isEdit ? EditRemoveFile(index) : removeFile(index)
              }
            >
              <i className="dripicons-cross"></i>
            </Link>
          </Col>
        </Row>
      </div>
    </Card>
  );



  return (
    <>
      <Dropzone
        onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
        multiple
        maxFiles={10} // Set the max number of files you want to allow
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone" {...getRootProps()}>
            <div className="dz-message needsclick">
              <input {...getInputProps()} />
              <i className="h3 text-muted dripicons-cloud-upload"></i>
              <h4>Click to upload.</h4>
              <span className="text-muted font-13">
                (This is just a demo dropzone. Selected files are{" "}
                <strong>not</strong> actually uploaded.)
              </span>
            </div>
          </div>
        )}
      </Dropzone>
      {loading && (
        <div className="text-center my-3">
          <Spinner animation="border" variant="secondary" />
        </div>
      )}

      <div className="dropzone-previews mt-3" id="file-previews" style={{ maxHeight: '300px', overflowY: selectedFiles.length > 2 ? 'auto' : 'hidden' }}>
        {selectedFiles.map((file: FileType, index: number) =>
          renderFileCard(file, index, !!props.is_edit)
        )}
      </div>

      {/* <div className="dropzone-previews mt-3" id="file-previews">
        {selectedFiles.map((file: any, index: any) =>
          renderFileCard(file, index, !!props.is_edit)
        )}
      </div> */}
    </>
  );
};

export default FileUploader;
